import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { USER_GROUPS } from '../../../../core/auth/model/user-groups.enum';
import {
  selectUserAssignedGroups,
  selectUserEmail
} from '../../../../core/auth/store/auth.selectors';
import { UnsubmittedErrorStateMatcher } from '../../../../shared/error-state-matchers/unsubmitted-error-state.matcher';
import { selectWizardCustomer } from '../../../signin-wizard/components/wizard/store/wizard.selectors';
import { selectCustomerName } from '../../../user/home/customer/store/customer.selectors';
import { ContactMessage } from '../model/contact-message.model';
import * as contactActions from '../store/contact-modal.actions';
import * as contactSelectors from '../store/contact-modal.selectors';

@Component({
  selector: 'snxt-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactModalComponent implements OnInit {
  contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    subject: ['', Validators.required],
    message: ['', Validators.required]
  });
  matcher = new UnsubmittedErrorStateMatcher();

  isSending$: Observable<boolean>;
  isSendFailed$: Observable<boolean>;
  isSendSucceeded$: Observable<boolean>;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectUserEmail)
      .pipe(
        take(1),
        tap(email => this.contactForm.controls.email.setValue(email))
      )
      .subscribe(noop);

    this.store
      .select(selectUserAssignedGroups)
      .pipe(
        take(1),
        filter(groups => groups.some(g => g === USER_GROUPS.CUSTOMER)),
        switchMap(() => this.store.select(selectCustomerName)),
        tap(name => this.contactForm.controls.name.setValue(name))
      )
      .subscribe(noop);

    this.store
      .select(selectUserAssignedGroups)
      .pipe(
        take(1),
        filter(groups => !groups || groups.length === 0),
        switchMap(() => this.store.select(selectWizardCustomer)),
        tap(wCustomer =>
          this.contactForm.controls.name.setValue(
            wCustomer.firstName + ' ' + wCustomer.lastName
          )
        )
      )
      .subscribe(noop);

    this.isSendSucceeded$ = this.store.select(
      contactSelectors.selectIsSendMessageSucceeded
    );

    this.isSending$ = this.store
      .select(contactSelectors.selectIsSendingMessage)
      .pipe(
        withLatestFrom(this.isSendSucceeded$),
        map(([isSending, isSent]) => {
          if (!isSending && !isSent) {
            this.contactForm.enable();
          } else {
            this.contactForm.disable();
          }
          return isSending;
        })
      );

    this.isSendFailed$ = this.store.select(
      contactSelectors.selectIsSendMessageFailed
    );
  }

  sendMessage() {
    const contactMessage: ContactMessage = this.contactForm.value;
    this.store.dispatch(
      contactActions.sendContactInformation({ contactMessage })
    );
  }
}
