import { Injectable } from '@angular/core';
import { HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { parse } from 'url';
import { AwsHeaders } from '../model/aws-headers.enum';
import { HashHelperService } from './hash-helper.service';

const EXCLUDED_HEADERS = ['cache-control', 'authority', 'scheme', 'pragma'];
const NEWLINE = '\n';
const AWS_SERVICE = 'execute-api';
const SIGNATURE_TYPE = 'aws4_request';
const AUTH_HEADER_ALGORITHM = 'AWS4-HMAC-SHA256';
const JSON_CONTENT_TYPE = 'application/json; charset=UTF-8';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestSignatureService {
  constructor(
    private datePipe: DatePipe,
    private hashHelperService: HashHelperService
  ) {}

  signRequest(
    sessionToken: string,
    secretKey: string,
    accessKey: string,
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const nowDate = new Date(Date.now());
    let iso8601Date = this.datePipe.transform(
      nowDate,
      "yyyyMMdd'T'HHmmss'Z'",
      '+0000'
    );
    let shortDateString = this.datePipe.transform(nowDate, 'yyyyMMdd', '+0000');
    let parsedUrl = parse(request.url);

    const canonicalHeadersArray = this.getCanonicalHeadersArray(
      request.headers,
      request.body,
      iso8601Date,
      sessionToken,
      parsedUrl.host
    );
    const signedHeaders = this.getSignedHeaders(canonicalHeadersArray);

    const canonicalRequest = this.getCanonicalRequest(
      request.body,
      request.method,
      canonicalHeadersArray,
      signedHeaders,
      parsedUrl.path,
      request.params
    );

    const stringToSign = this.getStringToSign(
      iso8601Date,
      shortDateString,
      canonicalRequest
    );

    const signingKey = this.getSignatureKey(
      secretKey,
      shortDateString,
      environment.Auth.region,
      AWS_SERVICE
    );
    const signature = this.getSignature(signingKey, stringToSign);

    const authorizationHeaderValue = this.getAuthHeaderValue(
      accessKey,
      shortDateString,
      signedHeaders,
      signature
    );

    let authorizedRequest = request.clone({
      headers: request.headers
        .set(AwsHeaders.DATE, iso8601Date)
        .set(AwsHeaders.SECURITY_TOKEN, sessionToken)
        .set(AwsHeaders.AUTHORIZATION, authorizationHeaderValue)
    });

    if (
      request.body &&
      !request.headers
        .keys()
        .some(h => h.toLowerCase() === AwsHeaders.CONTENT_TYPE)
    ) {
      authorizedRequest = authorizedRequest.clone({
        headers: authorizedRequest.headers.set(
          AwsHeaders.CONTENT_TYPE,
          JSON_CONTENT_TYPE
        )
      });
    }

    return authorizedRequest;
  }

  private getCanonicalHeadersArray(
    headers: HttpHeaders,
    body: any,
    iso8601Date: string,
    sessionToken: string,
    host: string
  ): string[] {
    let canonicalHeaders = [];
    const headerKeys = headers
      .keys()
      .sort()
      .filter(h => EXCLUDED_HEADERS.indexOf(h) < 0);
    for (let i = 0; i < headerKeys.length; i++) {
      canonicalHeaders.push(
        headerKeys[i].toLowerCase() + ':' + headers.get(headerKeys[i]).trim()
      );
    }
    canonicalHeaders.push(`${AwsHeaders.HOST}:${host}`);
    canonicalHeaders.push(`${AwsHeaders.DATE}:${iso8601Date}`);
    canonicalHeaders.push(`${AwsHeaders.SECURITY_TOKEN}:${sessionToken}`);
    if (body && !canonicalHeaders.includes('content-type')) {
      canonicalHeaders.push(`${AwsHeaders.CONTENT_TYPE}:${JSON_CONTENT_TYPE}`);
    }
    return canonicalHeaders;
  }

  private getSignedHeaders(canonicalHeaders: string[]): string {
    const signedHeaders = canonicalHeaders
      .map(h => h.split(':')[0])
      .sort()
      .join(';');
    return signedHeaders;
  }

  getCanonicalQuery(requestQuery: HttpParams) {
    if (!requestQuery) {
      return '';
    }

    let queryKeys = requestQuery.keys();
    let reducedQuery = queryKeys.reduce<Object>(
      (previous, current, index, array) => {
        if (!current) return previous;
        previous[encodeURIComponent(current)] = requestQuery.get(current);
        return previous;
      },
      {}
    );

    let encodedQueryParams = [];
    Object.keys(reducedQuery)
      .sort()
      .forEach(key => {
        encodedQueryParams.push(
          key + '=' + encodeURIComponent(reducedQuery[key])
        );
      });
    const canonicalQueryString = encodedQueryParams.join('&');
    return canonicalQueryString;
  }

  urlParamsToObject(params: string) {
    let paramsObj = JSON.parse(
      '{"' +
        decodeURI(params)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    return paramsObj;
  }

  getCanonicalRequest(
    requestBody: any,
    requestMethod: any,
    canonicalHeaders: string[],
    signedHeaders: string,
    urlPath: string,
    queryParams: HttpParams
  ): string {
    const canonicalUrl = urlPath ? urlPath : '/';
    const canonicalQueryString = this.getCanonicalQuery(queryParams);

    const hashedPayload = this.hashHelperService.hashSha256Hex(requestBody);

    const stringCanonicalHeaders = canonicalHeaders.sort().join(NEWLINE);

    const canonicalRequest =
      requestMethod +
      NEWLINE +
      encodeURI(canonicalUrl) +
      NEWLINE +
      canonicalQueryString +
      NEWLINE +
      stringCanonicalHeaders +
      NEWLINE +
      NEWLINE +
      signedHeaders +
      NEWLINE +
      hashedPayload;

    return canonicalRequest;
  }

  private parseUrlParam(rawParam: string) {
    const paramParts = rawParam.split('=');
    const paramName = encodeURI(paramParts[0]);
    const paramValue = paramParts.length > 1 ? encodeURI(paramParts[1]) : '';
    const encodedParam = `${paramName}=${paramValue}`;
    return encodedParam;
  }

  getStringToSign(
    iso8601Date: string,
    dateStamp: string,
    canonicalRequest: string
  ): string {
    const hashedCanonicalRequest = this.hashHelperService.hashSha256Hex(
      canonicalRequest
    );

    const stringToSign =
      AUTH_HEADER_ALGORITHM +
      NEWLINE +
      iso8601Date +
      NEWLINE +
      `${dateStamp}/${environment.Auth.region}/${AWS_SERVICE}/${SIGNATURE_TYPE}` +
      NEWLINE +
      hashedCanonicalRequest;

    return stringToSign;
  }

  getSignatureKey(secretKey, dateStamp, region, service) {
    const dateKey = this.hashHelperService.hmacSha256AsBytes(
      `AWS4${secretKey}`,
      dateStamp
    );
    const dateRegionKey = this.hashHelperService.hmacSha256AsBytes(
      dateKey,
      region
    );
    const dateRegionServiceKey = this.hashHelperService.hmacSha256AsBytes(
      dateRegionKey,
      service
    );
    const signingKey = this.hashHelperService.hmacSha256AsBytes(
      dateRegionServiceKey,
      SIGNATURE_TYPE
    );

    return signingKey;
  }

  getSignature(signingKey: any, stringToSign: string): string {
    const signature = this.hashHelperService.hmacSha256AsBytes(
      signingKey,
      stringToSign
    );

    return signature.toString();
  }

  private getAuthHeaderValue(
    accessKey: string,
    shortDateString: string,
    signedHeaders: string,
    signature: string
  ): string {
    const credential = `${accessKey}/${shortDateString}/${environment.Auth.region}/${AWS_SERVICE}/${SIGNATURE_TYPE}`;
    const authValue = `${AUTH_HEADER_ALGORITHM} Credential=${credential}, SignedHeaders=${signedHeaders}, Signature=${signature}`;
    return authValue;
  }
}
