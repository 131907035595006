import { createSelector } from '@ngrx/store';
import { selectUserState } from '../../../user.state';

export const selectCustomerState = createSelector(
  selectUserState,
  userState => userState.customer
);

export const selectIsSavingCustomerProfile = createSelector(
  selectCustomerState,
  customer => customer.isSavingCustomer
);

export const selectCustomer = createSelector(
  selectCustomerState,
  cs => cs.customer
);

export const selectCustomerName = createSelector(
  selectCustomerState,
  cs => (cs.customer?.firstName || '') + ' ' + (cs.customer?.lastName || '')
);

export const selectCustomerAddress = createSelector(
  selectCustomerState,
  cs => cs.customer?.homeAddress
);

export const selectCustomerBillingAddress = createSelector(
  selectCustomerState,
  cs => cs.customer?.billingAddress
);

export const selectCustomerPaymentMethods = createSelector(
  selectCustomerState,
  cs => cs.customer.paymentMethods
);

export const selectCustomerProfileLastSave = createSelector(
  selectCustomerState,
  st => st.lastSave
);

export const selectIsCustomerProfileSaveFailed = createSelector(
  selectCustomerState,
  st => st.islastSaveFailed
);
