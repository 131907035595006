<div class="login-component">
  <ng-container *ngIf="isLoading$ | async then loading else error">

  </ng-container>
</div>

<ng-template #loading>
  <div class="app-loading">
    <div class="logo">
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <ng-container *ngIf="error$ | async else loading">
    <div class="error">
      <img src="../../../../assets/logo_gunmetal.png" alt="prepaytolls logo">
      <h4>Something went wrong</h4>
      <p>
        Please refresh and try again, <br> or if the problem persists contact support.
      </p>
      <p class="error-info"><i>
          {{error$ | async}}
        </i>
      </p>
    </div>
  </ng-container>
</ng-template>
