import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Customer } from '../../../../../shared/models/customer/customer.model';
import * as WizardActions from './wizard.actions';

export const wizardFeatureKey = 'wizard';

export interface WizardState {
  customer: Customer;
  isSaving: boolean;
}

export const initialState: WizardState = {
  customer: null,
  isSaving: false
};

export const wizardReducer = createReducer(
  initialState,
  on(WizardActions.actionInitializeWizard, (state, action) => ({
    ...state,
    customer: { ...new Customer(), email: action.email }
  })),
  on(WizardActions.actionSaveWizard, state => ({
    ...state,
    isSaving: true
  })),
  on(WizardActions.actionSaveWizardSuccess, state => ({
    ...state,
    isSaving: false
  })),
  on(WizardActions.actionSaveWizardFailure, state => ({
    ...state,
    isSaving: false
  }))
);
