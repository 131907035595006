import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DATE_RANGES } from '../../../../../shared/components/date-selector/model/date-ranges.enum';
import { FilterFieldValue } from '../../../../../shared/models/entities-tables/filter-field-value';
import { Transaction } from '../../../../../shared/models/transaction.model';
import * as SortFilterReducer from '../../../../../shared/sort-filter-page-entities-store/sort-filter-page-entities.reducer';
import { newActivityInformationReceived } from '../../user-dashboard/store/user-dashboard.actions';
import * as TripActions from './trips.actions';

export const tripsFeatureKey = 'trips';

export interface TripsState
  extends EntityState<Transaction>,
    SortFilterReducer.SortFilterPageState {
  selectedDateRange: FilterFieldValue;
}

export const tripsAdapter: EntityAdapter<Transaction> = createEntityAdapter<
  Transaction
>({ selectId: (transaction: Transaction) => transaction.id });
const { selectAll } = tripsAdapter.getSelectors();
export const selectTrips = selectAll;

export const initialState: TripsState = tripsAdapter.getInitialState({
  ...SortFilterReducer.sortFilterInitialState,
  activeSortField: { field: 'transactionDateTime', direction: 'desc' },
  selectedDateRange: {
    filterName: 'transactionDateTime',
    filterValue: [DATE_RANGES.LAST_WEEK]
  }
});

export const tripsReducer = createReducer(
  initialState,
  on(TripActions.loadTripsFirstPage, (state, action) =>
    SortFilterReducer.getLoadFirstPageNewState(state, action, tripsAdapter)
  ),
  on(TripActions.loadTrips, (state, action) =>
    SortFilterReducer.getLoadEntitiesNewState(state, action, tripsAdapter)
  ),
  on(TripActions.changeTripsPage, (state, action) =>
    SortFilterReducer.getChangePageNewState(state, action, tripsAdapter)
  ),
  on(TripActions.changeTripsDateRange, (state, action) => ({
    ...state,
    selectedDateRange: action.range
  })),
  on(TripActions.searchTrips, (state, action) =>
    SortFilterReducer.getSearchNewState(state, action)
  ),
  on(TripActions.filterTrips, (state, action) =>
    SortFilterReducer.getFilterNewState(state, action)
  ),
  on(TripActions.sortTrips, (state, action) =>
    SortFilterReducer.getSortNewState(state, action)
  ),
  on(TripActions.loadTripsSuccess, (state, action) =>
    SortFilterReducer.getEntitiesLoadedNewState(state, action, tripsAdapter)
  ),
  on(TripActions.loadTripsError, (state, action) =>
    SortFilterReducer.getEntitiesLoadFailureNewState(state, tripsAdapter)
  ),
  on(newActivityInformationReceived, (state, action) => ({
    ...state,
    isLoaded: false
  }))
);
