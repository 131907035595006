import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';

export class AdditionalPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return (data: any) => {
      const action = actionHandler.call(this, data);
      if (action && data) {
        if (data.items || data.count || data.lastItem) {
          (action as any).payload.totalCount = data.count;
          (action as any).payload.lastItem = data.lastItem;
          (action as any).payload.data = data.items;
        }
        if (data.hits) {
          (action as any).payload.totalHits = data.hits.total.value;
          (action as any).payload.totalHitsRelation = data.hits.total.relation;
          (action as any).payload.data = data.hits.hits.map(hit => hit._source);
        }
      }
      return action;
    };
  }
}
