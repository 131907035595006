import { Action } from '@ngrx/store';
import { COMPARATIVE_RELATION } from '../enums/relations';
import { FilterFieldValue } from '../models/entities-tables/filter-field-value';
import { SortFieldDirection } from '../models/entities-tables/sort-field-direction';
import { SORTFILTERPAGEACTIONS } from './sort-filter-page-actions';
import {
  FilterAvailableOptions,
  SearchEntitiesResponse
} from '../models/entities-tables/search-entities-response';
import { EntityState, EntityAdapter } from '@ngrx/entity';
import { TypedAction } from '@ngrx/store/src/models';

export const sortFilterPageEntitiesFeatureKey = 'sortFilterPageEntities';

export interface SortFilterPageState {
  isLoading: boolean;
  isLoaded: boolean;
  isLoadFailure: boolean;
  count: number;
  countRelation: COMPARATIVE_RELATION;
  pageSize: number;
  pageIndex: number;
  searchTerm: string;
  activeFilters: FilterFieldValue[];
  activeSortField: SortFieldDirection | null;
  availableFilters: FilterAvailableOptions[];
}

export const sortFilterInitialState: SortFilterPageState = {
  isLoading: false,
  isLoaded: false,
  isLoadFailure: false,
  count: 0,
  countRelation: COMPARATIVE_RELATION.EQUAL,
  pageSize: 10,
  pageIndex: 0,
  searchTerm: '',
  activeFilters: [],
  activeSortField: null,
  availableFilters: []
};

export function sortFilterReducer<T extends SortFilterPageState>(
  pageName: string,
  initialState: T
) {
  return function reducer(state: T = initialState, action: Action) {
    switch (action.type) {
      case `[${pageName}] ${SORTFILTERPAGEACTIONS.LOAD}`:
        return {
          ...state,
          isLoading: true,
          isLoaded: false
        };
    }
  };
}

export function getLoadEntitiesNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(state: S, action: TypedAction<string>, entityAdapter: EntityAdapter<T>) {
  return entityAdapter.removeAll({
    ...state,
    isLoaded: false,
    isLoading: true,
    isLoadFailure: false
  });
}

export function getLoadFirstPageNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(state: S, action: TypedAction<string>, entityAdapter: EntityAdapter<T>) {
  return entityAdapter.removeAll({
    ...state,
    pageIndex: 0,
    isLoaded: false,
    isLoading: true,
    isLoadFailure: false
  });
}

export function getChangePageNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(
  state: S,
  action: { pageSize: number; pageIndex: number } & TypedAction<string>,
  entityAdapter: EntityAdapter<T>
) {
  let newState = {
    ...state,
    isLoading: true,
    isLoaded: false,
    isLoadFailure: false,
    pageSize: action.pageSize,
    pageIndex: action.pageIndex
  };
  if (action.pageSize !== state.pageSize) {
    newState.pageIndex = 0;
  }
  return entityAdapter.removeAll(newState);
}

export function getSearchNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(state: S, action: { searchTerm: string | string[] } & TypedAction<string>) {
  return {
    ...state,
    searchTerm: action.searchTerm,
    activeFilters: [],
    isLoadFailure: false
  };
}

export function getFilterNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(state: S, action: { filter: FilterFieldValue } & TypedAction<string>) {
  let newState = {
    ...state,
    isLoadFailure: false
  };
  let newFiltersList = state.activeFilters.filter(
    f => f.filterName !== action.filter.filterName
  );

  if (action.filter.filterValue.length > 0) {
    newFiltersList.push(action.filter);
  }

  newState.activeFilters = newFiltersList;
  return newState;
}

export function getSortNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(state: S, action: { sortField: SortFieldDirection } & TypedAction<string>) {
  return {
    ...state,
    isLoadFailure: false,
    activeSortField: action.sortField.direction != '' ? action.sortField : null
  };
}

export function getEntitiesLoadedNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(
  state: S,
  action: { data: SearchEntitiesResponse<T> } & TypedAction<string>,
  entityAdapter: EntityAdapter<T>
) {
  let newState = {
    ...state,
    isLoaded: true,
    isLoading: false,
    isLoadFailure: false,
    count: action.data.count
  };

  let activeFilterNames = state.activeFilters.map(f => f.filterName);

  let newFilterOptions = state.availableFilters.filter(f =>
    containsStringInArray(f.name, activeFilterNames)
  );
  let newFilterOptionsFromData = action.data.filtersOptions.filter(
    f => !containsStringInArray(f.name, activeFilterNames)
  );
  newFilterOptions.push(...newFilterOptionsFromData);
  newState.availableFilters = newFilterOptions;
  return entityAdapter.setAll(action.data.entities, newState);
}

export function getEntitiesLoadFailureNewState<
  T,
  S extends EntityState<T> & SortFilterPageState
>(state: S, entityAdapter: EntityAdapter<T>) {
  let newState = {
    ...state,
    isLoaded: false,
    isLoading: false,
    isLoadFailure: true
  };
  return entityAdapter.removeAll(newState);
}

function containsStringInArray(
  valueStr: string,
  valueArray: string[]
): boolean {
  return !!valueArray.find(
    v => valueStr.indexOf(v) > -1 || v.indexOf(valueStr) > -1
  );
}
