import { createAction, props } from '@ngrx/store';
import { Vehicle } from '../../../../shared/models/vehicle/vehicle.model';

export const actionLoadVehicles = createAction('[Vehicles Page] Load Vehicles');

export const actionEditVehicle = createAction(
  '[Vehicles Page] Edit Vehicle',
  props<{ vehicleId: string }>()
);

export const actionCancelVehicleEdit = createAction(
  '[Vehicles Page] Cancel Vehicle Edition'
);

export const actionStartCreatingVehicle = createAction(
  '[Vehicles Page] Start Creating Vehicle'
);

export const actionSaveVehicle = createAction(
  '[Vehicles Page] Save Vehicle',
  props<{ vehicle: Vehicle }>()
);

export const actionSaveVehicleSuccess = createAction(
  '[Vehicles Page] Save Vehicle Success',
  props<{ vehicle: Vehicle }>()
);

export const actionSaveVehicleFailure = createAction(
  '[Vehicles Page] Save Vehicle Failure',
  props<{ vehicle: Vehicle }>()
);

export const actionSelectVehicle = createAction(
  '[Vehicles Page] Select Vehicle',
  props<{ vehicleId: string }>()
);

export const actionDeselectVehicle = createAction(
  '[Vehicles Page] Deselect Vehicle'
);

export const actionDeleteVehicle = createAction(
  '[Vehicles Pape] Delete Vehicle',
  props<{ vehicle: Vehicle }>()
);

export const actionDeleteVehicleSuccess = createAction(
  '[Vehicles Pape] Delete Vehicle Success',
  props<{ vehicle: Vehicle }>()
);

export const actionDeleteVehicleFailure = createAction(
  '[Vehicles Pape] Delete Vehicle Failure',
  props<{ vehicle: Vehicle }>()
);

export const generateVehicleTransactions = createAction(
  '[Vehicles Page] Generate Vehicle Transactions'
);

export const generateVehicleTransactionsSuccess = createAction(
  '[Vehicles Page] Generate Vehicle Transactions Success'
);

export const generateVehicleTransactionsFailure = createAction(
  '[Vehicles Page] Generate Vehicle Transactions Failed'
);
