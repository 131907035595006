import { createAction, props } from '@ngrx/store';
import { DATE_RANGES } from '../../../../../shared/components/date-selector/model/date-ranges.enum';
import { FilterFieldValue } from '../../../../../shared/models/entities-tables/filter-field-value';
import { SearchEntitiesResponse } from '../../../../../shared/models/entities-tables/search-entities-response';
import { SortFieldDirection } from '../../../../../shared/models/entities-tables/sort-field-direction';
import { Invoice } from '../../../../operator/accounting/invoices/model/invoice.model';
import { Activity } from '../model/activity.model';
import { Payment } from '../model/payment.model';

const sectionName = '[Activities] ';

export const initialLoadActivities = createAction(
  sectionName + 'Load if not already loaded'
);

export const loadActivities = createAction(sectionName + 'Load Activities');

export const loadActivitiesFirstPage = createAction(
  sectionName + 'Load Activities First Page'
);

export const loadActivitiesSuccess = createAction(
  sectionName + 'Load Activities Success',
  props<{ data: SearchEntitiesResponse<Activity> }>()
);

export const loadActivitiesError = createAction(
  sectionName + 'Load Activities Error',
  props<{ error: string }>()
);

export const changeActivitiesPage = createAction(
  sectionName + 'Change Page',
  props<{ pageIndex: number; pageSize: number }>()
);

export const searchActivities = createAction(
  sectionName + 'Search',
  props<{ searchTerm: string | string[] }>()
);

export const sortActivities = createAction(
  sectionName + 'Sort',
  props<{ sortField: SortFieldDirection }>()
);

export const filterActivities = createAction(
  sectionName + 'Filter',
  props<{ filter: FilterFieldValue }>()
);

export const getInvoiceIfNotLoaded = createAction(
  sectionName + 'get invoice if not loaded',
  props<{ refId: string }>()
);

export const getInvoice = createAction(
  sectionName + ' get invoice ',
  props<{ refId: string }>()
);

export const getInvoiceSuccess = createAction(
  sectionName + ' get invoice Success',
  props<{ refId: string; invoice: Invoice }>()
);

export const getInvoiceFailure = createAction(
  sectionName + ' get invoice Failure',
  props<{ refId: string }>()
);

export const getPaymentIfNotLoaded = createAction(
  sectionName + ' Get Payment If Not Loaded',
  props<{ refId: string }>()
);

export const getPayment = createAction(
  sectionName + ' Get Payment',
  props<{ refId: string }>()
);

export const getPaymentSuccess = createAction(
  sectionName + ' Get Payment Success',
  props<{ refId: string; payment: Payment }>()
);

export const getPaymentFailure = createAction(
  sectionName + ' Get Payment Failure',
  props<{ refId: string }>()
);

export const downloadInvoicePdf = createAction(
  sectionName + ' Download Invoice Pdf',
  props<{ id: string }>()
);

export const downloadInvoicePdfSuccess = createAction(
  sectionName + ' Download Invoice Pdf Success',
  props<{ id: string }>()
);

export const downloadInvoicePdfFailure = createAction(
  sectionName + ' Download Invoice Pdf Failure',
  props<{ id: string }>()
);

export const changeDateRange = createAction(
  sectionName + 'Change Date Range ',
  props<{ dateRange: FilterFieldValue }>()
);
