import { Language } from '../../shared/components/language-selector/languages';
import { AppState } from '../core.state';

export const NIGHT_MODE_THEME = 'BLACK-THEME';

export interface SettingsState {
  language: Language;
  themeName: string;
  autoNightMode: boolean;
  nightTheme: string;
  stickyHeader: boolean;
  pageAnimations: boolean;
  pageAnimationsDisabled: boolean;
  elementsAnimations: boolean;
  hour: number;
}

export interface State extends AppState {
  settings: SettingsState;
}
