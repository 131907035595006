import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ContactMessage } from '../model/contact-message.model';

@Injectable({
  providedIn: 'root'
})
export class ContactModalService {
  constructor(private http: HttpClient) {}

  sendMessage(message: ContactMessage): Observable<any> {
    const url = environment.awsServerUrl + 'contact';
    return this.http.post(url, message);
  }
}
