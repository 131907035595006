import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ErrorScreenComponent } from './shared/components/error-screen/error-screen.component';
import { AuthGuard } from './core/auth/guard/auth.guard';
import { LoginComponent } from './core/auth/components/login/login.component';
import { LogoutComponent } from './core/auth/components/logout/logout.component';
import { AdminGuard } from './core/auth/guard/admin.guard';
import { UserGuard } from './core/auth/guard/user.guard';
import { OperatorGuard } from './core/auth/guard/operator.guard';
import { SigninWizardGuard } from './core/auth/guard/signin-wizard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'signup-wizard',
    canActivate: [SigninWizardGuard],
    canLoad: [SigninWizardGuard],
    loadChildren: () =>
      import('./features/signin-wizard/signin-wizard.module').then(
        m => m.SigninWizardModule
      )
  },
  {
    path: 'user',
    canActivate: [UserGuard],
    canLoad: [UserGuard],
    loadChildren: () =>
      import('./features/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    canLoad: [AdminGuard],
    loadChildren: () =>
      import('./features/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'operator',
    canActivate: [OperatorGuard],
    canLoad: [OperatorGuard],
    loadChildren: () =>
      import('./features/operator/operator.module').then(m => m.OperatorModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/settings/settings.module').then(m => m.SettingsModule)
  },
  { path: 'error', component: ErrorScreenComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '**',
    redirectTo: 'user'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
