import { createSelector } from '@ngrx/store';
import { selectContactModalState } from './contact-modal.reducer';

export const selectIsSendingMessage = createSelector(
  selectContactModalState,
  state => state.isSendingMessage
);

export const selectIsSendMessageFailed = createSelector(
  selectContactModalState,
  state => state.isSendMessageFailed
);

export const selectIsSendMessageSucceeded = createSelector(
  selectContactModalState,
  state => state.isSendMessageSucceeded
);
