import { createAction, props } from '@ngrx/store';
import { CustomerDashboardDto } from '../model/customer-dashboard-dto';

export const loadUserIfNotLoaded = createAction(
  '[User Dashboard] Load User if not loaded'
);

export const loadUserDashboard = createAction(
  '[User Dashboard] Load User Dashboard'
);

export const loadUserDashboardSuccess = createAction(
  '[User Dashboard] Load User Dashboard Success',
  props<{ data: CustomerDashboardDto }>()
);

export const loadUserDashboardFailure = createAction(
  '[User Dashboard] Load User Dashboard Failure',
  props<{ error: any }>()
);

export const redirectToFirstSigninWizard = createAction(
  '[User Dashboard] Redirect to first signin Wizard'
);

export const updateCustomer = createAction('[Customer] UpdateCustomer');

export const newActivityInformationReceived = createAction(
  '[User Dashboard] new activity information received'
);
