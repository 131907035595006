import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { USER_GROUPS } from '../model/user-groups.enum';

@Injectable({
  providedIn: 'root'
})
export class JwtDecoderService {
  constructor() {}

  static decodeToken(token: string) {
    return jwt_decode(token);
  }

  static getCurrentUserAssignedGroups(token: string): string[] {
    let groups = this.decodeToken(token)['cognito:groups']?.map(groupName =>
      groupName.toLowerCase()
    );

    let existingPortalGroups = groups?.filter(
      g => USER_GROUPS[g.toUpperCase()]
    );
    return existingPortalGroups;
  }

  static getCurrentUserEmail(idToken: string) {
    let email = this.decodeToken(idToken).email;
    return email;
  }
}
