import { createAction, props } from '@ngrx/store';
const SECTION_NAME = '[Make A Payment] ';

export const actionOpenMakeAPaymentModal = createAction(
  SECTION_NAME + 'Open Modal'
);

export const actionCreatePayment = createAction(
  SECTION_NAME + 'Create a Payment',
  props<{ amount?: number; phoneNumber: string; code: string }>()
);

export const actionCreatePaymentSuccess = createAction(
  SECTION_NAME + 'Create a Payment Success'
);

export const actionCreatePaymentFailure = createAction(
  SECTION_NAME + 'Create a Payment Failure'
);
