import { InMemoryDbService } from 'angular-in-memory-web-api';
import { RATES } from './GetData/rates-init-db';
import { BALANCESUMMARY } from './GetData/balance-summary-init-db';
import { ACCOUNT_INFORMATION } from './GetData/account-information-init-db';
import { TRANSACTIONS } from './GetData/transactions-init-db';

export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const rates = RATES;
    const balanceSummary = BALANCESUMMARY;
    const accountInformation = ACCOUNT_INFORMATION;
    const transactions = TRANSACTIONS;
    return {
      // vehicles,
      // customers,
      rates,
      balanceSummary,
      accountInformation
      // transactions
    };
  }
}
