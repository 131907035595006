import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import {
  vehiclesReducer,
  VehiclesState
} from './components/vehicles/store/vehicles.reducer';
import {
  wizardReducer,
  WizardState
} from './components/wizard/store/wizard.reducer';

export const FEATURE_NAME = 'signupWizard';

export interface SignupWizardState {
  wizard: WizardState;
  vehicles: VehiclesState;
}

export const selectSignupWizardState = createFeatureSelector<
  State,
  SignupWizardState
>(FEATURE_NAME);

export interface State {
  [FEATURE_NAME]: SignupWizardState;
}

export const reducers: ActionReducerMap<SignupWizardState> = {
  wizard: wizardReducer,
  vehicles: vehiclesReducer
};
