import { ActionReducer, INIT } from '@ngrx/store';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { AppState } from '../core.state';
import { ObjectUtil } from '../../shared/utility/object-utility';

export function initStateFromLocalStorage(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function(state, action) {
    const newState = reducer(state, action);
    if ([INIT.toString()].includes(action.type)) {
      const localStorageState = LocalStorageService.loadInitialState();
      const compoundNewState = ObjectUtil.ObjectAssignNestedObjects<AppState>(
        newState,
        localStorageState
      );
      return compoundNewState;
    }
    return newState;
  };
}
