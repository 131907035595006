import { Action, createReducer, on } from '@ngrx/store';
import { Customer } from '../../../../../shared/models/customer/customer.model';
import { actionSaveVehicle } from '../../../../shared-features/vehicles/store/vehicles.actions';
import { loadUserDashboardSuccess } from '../../user-dashboard/store/user-dashboard.actions';
import {
  actionSaveCustomer,
  actionSaveCustomerRequestSuccess,
  actionSaveCustomerFailure,
  actionSaveCustomerSuccess
} from './customer.actions';

export const customerFeatureKey = 'customer';

export interface CustomerState {
  isSavingCustomer: boolean;
  lastSave: Date;
  islastSaveFailed: boolean;
  customer: Customer;
}

export const initialState: CustomerState = {
  isSavingCustomer: false,
  customer: null,
  lastSave: null,
  islastSaveFailed: false
};

export const customerReducer = createReducer(
  initialState,
  on(loadUserDashboardSuccess, (state, action) => ({
    ...state,
    customer: action.data.customer
  })),
  on(actionSaveCustomer, state => ({
    ...state,
    isSavingCustomer: true,
    islastSaveFailed: false
  })),
  on(actionSaveCustomerRequestSuccess, (state, action) => ({
    ...state,
    customer: action.customer
  })),
  on(actionSaveVehicle, state => ({
    ...state,
    isSavingCustomer: true,
    islastSaveFailed: false
  })),
  on(actionSaveCustomerSuccess, (state, action) => ({
    ...state,
    isSavingCustomer: false,
    lastSave: new Date(Date.now())
  })),
  on(actionSaveCustomerFailure, state => ({
    ...state,
    isSavingCustomer: false,
    islastSaveFailed: true
  }))
);
