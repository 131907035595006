import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  filter,
  map,
  mapTo,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { newAdminWebSocketMessage } from '../../../features/admin/web-socket/admin-web-socket.actions';
import { newOperatorWebSocketMessage } from '../../../features/operator/web-socket/operator-websocket.actions';
import { newWebSocketWizardMessage } from '../../../features/signin-wizard/store/websocket-wizard.actions';
import { newCustomerWebSocketMessage } from '../../../features/user/customer-websocket/customer-websocket.actions';
import { USER_GROUPS } from '../model/user-groups.enum';

import { WEB_SOCKET_DOMAIN } from '../model/web-socket-domain.enum';
import { selectUserAssignedGroups } from './auth.selectors';
import * as WebSocketActions from './web-socket.actions';
import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { WebSocketService } from '../services/web-socket.service';
import { interval } from 'rxjs';
const KEEP_ALIVE_INTERVAL = 300000;
const KEEP_ALIVE_MESSAGE = 'keepAlive';

@Injectable()
export class WebSocketEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private socketService: WebSocketService
  ) {}

  finalizeLoginSuccessSocketEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authFinalizeLoginSuccess),
      withLatestFrom(
        this.store.select(AuthSelectors.selectAccessKey),
        this.store.select(AuthSelectors.selectSecretKey),
        this.store.select(AuthSelectors.selectSessionToken)
      ),
      map(([, accessKey, secretKey, sessionToken]) => {
        this.socketService.setupWebSocket(accessKey, secretKey, sessionToken);
        return WebSocketActions.startKeepAliveSocket();
      })
    )
  );

  startKeepAliveSocketEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(WebSocketActions.startKeepAliveSocket),
      switchMap(() => {
        return interval(KEEP_ALIVE_INTERVAL).pipe(
          mapTo(WebSocketActions.keepAlive()),
          takeUntil(
            this.store
              .select(AuthSelectors.selectStopKeepingSocketAlive)
              .pipe(filter(i => !!i))
          )
        );
      })
    )
  );

  keepAliveSocketEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.keepAlive),
        tap(() => {
          this.socketService.sendMessage(KEEP_ALIVE_MESSAGE);
        })
      ),
    { dispatch: false }
  );

  closeSocketEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.closeSocket),
        tap(() => {
          this.socketService.closeSocket();
        })
      ),
    { dispatch: false }
  );

  webSocketMessageEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(WebSocketActions.newWebSocketMessage),
      withLatestFrom(this.store.select(selectUserAssignedGroups)),
      map(([action, groups]) => {
        switch (action.message.domain) {
          case WEB_SOCKET_DOMAIN.PAYMENT: {
            return newCustomerWebSocketMessage({ message: action.message });
          }
          case WEB_SOCKET_DOMAIN.CUSTOMER: {
            if (groups?.some(g => g === USER_GROUPS.CUSTOMER)) {
              return newCustomerWebSocketMessage({ message: action.message });
            }
            return newWebSocketWizardMessage({ message: action.message });
          }
          case WEB_SOCKET_DOMAIN.BILLING: {
            if (groups?.some(g => g === USER_GROUPS.ADMIN)) {
              return newAdminWebSocketMessage({ message: action.message });
            }
            if (groups?.some(g => g === USER_GROUPS.CUSTOMER)) {
              return newCustomerWebSocketMessage({ message: action.message });
            }
            return newOperatorWebSocketMessage({ message: action.message });
          }
          case WEB_SOCKET_DOMAIN.TRANSACTION: {
            if (groups?.some(g => g === USER_GROUPS.ADMIN)) {
              return newAdminWebSocketMessage({ message: action.message });
            }
            if (groups?.some(g => g === USER_GROUPS.OPERATOR)) {
              return newOperatorWebSocketMessage({ message: action.message });
            }
            return {
              type: '[Web Socket] New Unhandled Socket Message Received'
            };
          }
          default: {
            return {
              type: '[Web Socket] New Unhandled Socket Message Received'
            };
          }
        }
      })
    )
  );
}
