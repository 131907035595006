import { PaymentMethod } from './payment-method.model';
import { Address } from './adress.model';
import { Vehicle } from '../vehicle/vehicle.model';

export class Customer {
  accountNumber: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  email: string;
  primaryPhone: string;
  prepayReplenishmentAmount: number;
  homeAddress: Address;
  billingAddress: Address;
  paymentMethods?: PaymentMethod[];
  timeZone: string;
  vehicles?: Vehicle[];

  constructor() {
    this.accountNumber = '';
    this.firstName = '';
    this.lastName = '';
    this.companyName = '';
    this.email = '';
    this.primaryPhone = '';
    this.prepayReplenishmentAmount = 0;
    this.homeAddress = {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US'
    };
    this.billingAddress = {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    };
  }
}
