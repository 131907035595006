import {
  EntityCollectionReducerMethods,
  EntityDefinition,
  EntityCollection,
  EntityAction
} from '@ngrx/data';

export class AdditionalEntityCollectionReducerMethods<
  T
> extends EntityCollectionReducerMethods<T> {
  constructor(
    public entityName: string,
    public definition: EntityDefinition<T>
  ) {
    super(entityName, definition);
  }

  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const entityCollection = super.queryManySuccess(collection, action);
    const payload = action.payload as any;
    if (payload.totalCount || payload.lastItem) {
      if (payload.totalCount !== undefined) {
        (entityCollection as any).totalCount = payload.totalCount;
      }
      (entityCollection as any).lastItem = payload.lastItem;
      (entityCollection as any).lastItemList = LastItemListReducerHelper.updateLastItemList(
        (entityCollection as any).lastItemList,
        payload.lastItem
      );
    }
    this.setElasticSearchHitsMetadata(entityCollection, payload);
    return entityCollection;
  }

  protected queryAllSuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const entityCollection = super.queryAllSuccess(collection, action);
    if ((action.payload as any).totalCount) {
      (entityCollection as any).totalCount = (action.payload as any).totalCount;
      (entityCollection as any).lastItem = (action.payload as any).lastItem;
    }

    this.setElasticSearchHitsMetadata(entityCollection, action.payload);
    return entityCollection;
  }

  private setElasticSearchHitsMetadata(entityCollection, payload) {
    if (payload.totalHits) {
      entityCollection.totalHits = payload.totalHits;
      entityCollection.totalHitsRelation = payload.totalHitsRelation;
    }
  }
}

export class LastItemListReducerHelper {
  static updateLastItemList(list: any[], newItem: any): any[] {
    const newList = list.map(item => Object.assign({}, item));
    if (list.find(item => item === newItem)) {
      return newList;
    }
    return newList.concat(newItem);
  }
}
