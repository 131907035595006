import { Injectable } from '@angular/core';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import {
  tap,
  switchMap,
  map,
  catchError,
  withLatestFrom,
  filter
} from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { CodeVerifierService } from '../services/code-verifier.service';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import { LastVisitedUrlService } from '../services/last-visited-url.service';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { SessionStorageService } from '../../session-storage/session-storage.service';
import { JwtDecoderService } from '../services/jwt-decoder.service';
import { closeSocket } from './web-socket.actions';
export const ERROR_ROUTE = 'error';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private codeVerifierService: CodeVerifierService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private lastVisitedUrlService: LastVisitedUrlService,
    private sessionStorageService: SessionStorageService
  ) {}

  initiateLoginEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authInitiateLogin),
        tap(action => {
          const codeChallenge = this.codeVerifierService.getCodeChallenge();
          this.localStorageService.setItem(
            'AUTH.verifier',
            codeChallenge.verifier
          );
          return this.authService.initiateLogin(
            environment.Auth.clientId,
            environment.Auth.response_type,
            environment.Auth.scope,
            environment.Auth.redirect_uri,
            action.redirectUrl,
            environment.Auth.identityProvider,
            codeChallenge.challenge
          );
        })
      ),
    { dispatch: false }
  );

  initiateSilentLoginEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authInitiateSilentLogin),
      map(() => AuthActions.authRefreshTokens())
    )
  );

  getTokensEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authGetTokens),
      withLatestFrom(this.store.select(AuthSelectors.selectAuthVerifier)),
      switchMap(([action, verifier]) => {
        return this.authService
          .getAuthTokens(
            action.authCode,
            environment.Auth.clientId,
            environment.Auth.redirect_uri,
            verifier
          )
          .pipe(
            map(tokens => {
              const userGroups = JwtDecoderService.getCurrentUserAssignedGroups(
                tokens.id_token
              );
              const email = JwtDecoderService.getCurrentUserEmail(
                tokens.id_token
              );
              return AuthActions.authGetTokensSuccess({
                tokens: tokens,
                userGroups,
                email
              });
            }),
            catchError(error => of(AuthActions.authGetTokensFailure({ error })))
          );
      })
    )
  );

  AuthFailureEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.authGetTokensFailure,
        AuthActions.getCredentialsForIdentityFailure,
        AuthActions.getIdentityFalure
      ),
      map(action => {
        if ((action.error as any).error === 'invalid_grant') {
          return AuthActions.fatalAuthError({
            error: `Invalid authentication code. Please refresh, or contact support if the problem persists.`
          });
        }
        return AuthActions.fatalAuthError({
          error: action?.error?.error?.error
        });
      })
    )
  );

  getTokensSuccessEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authGetTokensSuccess),
      map(action => {
        this.sessionStorageService.setItem(
          'AUTH.refresh-token',
          action.tokens.refresh_token
        );
        this.lastVisitedUrlService.setup();
        return AuthActions.getIdentityId();
      })
    )
  );

  initiateRefreshTokensEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authInitiateRefreshCredentials),
      withLatestFrom(
        this.store.select(AuthSelectors.selectIsRefreshingCredentials)
      ),
      filter(([, isrefreshing]) => !isrefreshing),
      map(() => {
        return AuthActions.authRefreshTokens();
      })
    )
  );

  refreshTokensEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authRefreshTokens),
      withLatestFrom(this.store.select(AuthSelectors.selectRefreshToken)),
      switchMap(([, token]) =>
        this.authService.refreshTokens(environment.Auth.clientId, token).pipe(
          map(tokens => {
            const userGroups = JwtDecoderService.getCurrentUserAssignedGroups(
              tokens.id_token
            );
            const email = JwtDecoderService.getCurrentUserEmail(
              tokens.id_token
            );

            return AuthActions.authRefreshTokensSuccess({
              tokens,
              userGroups,
              email
            });
          }),
          catchError(error =>
            of(AuthActions.authRefreshTokensFailure({ error }))
          )
        )
      )
    )
  );

  refreshTokensSuccessEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authRefreshTokensSuccess),
      map(() => {
        this.lastVisitedUrlService.setup();
        return AuthActions.getIdentityId();
      })
    )
  );

  refreshTokensFailureEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authRefreshTokensFailure),
      map(() => AuthActions.authLogout())
    )
  );

  logoutEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authLogout),
      map(() => {
        this.sessionStorageService.removeItem('AUTH.refresh-token');
        this.authService.getAuthLogout(
          environment.Auth.clientId,
          environment.Auth.redirect_logout_uri
        );
        return closeSocket();
      })
    )
  );

  getUserInfoEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authGetUserInfo),
      switchMap(() =>
        this.authService.getUserInfo().pipe(
          map(userInfo => AuthActions.authGetUserInfoSuccess({ userInfo })),
          catchError(error => of(AuthActions.authGetUserInfoFailure({ error })))
        )
      )
    )
  );

  getIdentityIdEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getIdentityId),
      withLatestFrom(this.store.select(AuthSelectors.selectIdToken)),
      switchMap(([action, idToken]) =>
        this.authService.getIdentityId(idToken).pipe(
          map(identity => AuthActions.getIdentitySuccess({ identity })),
          catchError(error => of(AuthActions.getIdentityFalure({ error })))
        )
      )
    )
  );

  getIdentitySuccessEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getIdentitySuccess),
      map(() => AuthActions.getCredentialsForIdentity())
    )
  );

  getCredentialsForIdentityEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCredentialsForIdentity),
      withLatestFrom(
        this.store.select(AuthSelectors.selectIdToken),
        this.store.select(AuthSelectors.selectIdentityId)
      ),
      switchMap(([action, idToken, identityId]) =>
        this.authService.getCredentialsForIdentity(idToken, identityId).pipe(
          map(credentials =>
            AuthActions.getCredentialsForIdentitySuccess({ credentials })
          ),
          catchError(error =>
            of(AuthActions.getCredentialsForIdentityFailure({ error }))
          )
        )
      )
    )
  );

  getCredentialsForIdentitySuccessEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCredentialsForIdentitySuccess),
      withLatestFrom(
        this.store.select(AuthSelectors.selectIsAuthenticated),
        this.store.select(AuthSelectors.selectIsRefreshingCredentials)
      ),
      filter(
        ([, isAuthenticated, isRefreshingTokens]) =>
          !isAuthenticated || isRefreshingTokens
      ),
      map(([, isAuthenticated, isRefreshingTokens]) => {
        if (!isAuthenticated) {
          return AuthActions.authFinalizeLoginSuccess();
        }
        if (isRefreshingTokens) {
          return AuthActions.authFinalizeRefreshCredentialsSuccess();
        }
      })
    )
  );

  finalizeLoginSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authFinalizeLoginSuccess),
        withLatestFrom(
          this.store.select(AuthSelectors.selectLastVisitedUrl),
          this.store.select(AuthSelectors.selectRequestedUrl),
          this.store.select(AuthSelectors.selectUserAssignedGroups)
        ),
        tap(([, lastVisitedUrl, requestedUrl, userGroups]) => {
          if (!userGroups || userGroups.length < 1) {
            return this.router.navigateByUrl('signup-wizard');
          }
          if (!!requestedUrl && requestedUrl.trim().length > 1) {
            this.router.navigateByUrl(requestedUrl);
            return;
          }
          if (!!lastVisitedUrl && lastVisitedUrl.trim().length > 1) {
            return this.router.navigateByUrl(lastVisitedUrl);
          }
          return this.router.navigateByUrl(userGroups[0]);
        })
      ),
    { dispatch: false }
  );

  finalizeRefreshCredentialsSuccessEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authFinalizeRefreshCredentialsSuccess),
      withLatestFrom(this.store.select(AuthSelectors.selectIsAuthenticated)),
      filter(([, isAuthenticated]) => !isAuthenticated),
      map(() => AuthActions.authFinalizeLoginSuccess())
    )
  );

  fatalAuthErrorEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.fatalAuthError),
        tap(action => {
          this.router.navigateByUrl('/error', {
            state: { error: action.error }
          });
        })
      ),
    { dispatch: false }
  );
}
