import { createAction, props } from '@ngrx/store';
import { ContactMessage } from '../model/contact-message.model';

export const openContactModal = createAction('[ContactModal] Open Modal');

export const closeContactModal = createAction(
  '[ContactModal] Close Contact Modal'
);

export const sendContactInformation = createAction(
  '[ContactModal] Send contact message',
  props<{ contactMessage: ContactMessage }>()
);

export const sendContactInformationSuccess = createAction(
  '[ContactModal] Send contact message success'
);

export const sendContactInformationFailure = createAction(
  '[ContactModal] Send contact message failure'
);
