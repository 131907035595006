import { Transaction } from '../../shared/models/transaction.model';

export const TRANSACTIONS: any[] = [
  {
    id: 1,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T23:35:04Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 575402,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 182,
    axleCount: 3,
    accountNumber: 372716,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3116,
    tollingPointName: 'SR91 Hancock Road SBOn MP278',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60Z',
    expectedAmount: 0.76,
    collectedAmount: null
  },
  {
    id: 2,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T14:37:52Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 817390,
    plateState: 'CO',
    plateCountry: 'United States',
    vehicleId: 11,
    axleCount: 3,
    accountNumber: 868852,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1721,
    tollingPointName: 'Lakeland Highlands Boulevard',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.57,
    collectedAmount: null
  },
  {
    id: 3,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T17:08:08Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 763190,
    plateState: 'NL',
    plateCountry: 'Canada',
    vehicleId: 640,
    axleCount: 4,
    accountNumber: 399234,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'COAPM',
    laneNumber: '05',
    laneName: '05S',
    expectedAmount: 0.84,
    collectedAmount: null
  },
  {
    id: 4,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T17:19:33Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 151305,
    plateState: 'AR',
    plateCountry: 'United States',
    vehicleId: 843,
    axleCount: 2,
    accountNumber: 228651,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3115,
    tollingPointName: 'SR91 Hancock Road NBOff MP278',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.78,
    collectedAmount: null
  },
  {
    id: 5,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T18:05:55Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 774761,
    plateState: 'IN',
    plateCountry: 'United States',
    vehicleId: 609,
    axleCount: 4,
    accountNumber: 756040,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '12',
    laneName: '12S',
    expectedAmount: 0.57,
    collectedAmount: null
  },
  {
    id: 6,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T09:28:56Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 669456,
    plateState: 'DE',
    plateCountry: 'United States',
    vehicleId: 471,
    axleCount: 4,
    accountNumber: 901413,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1431,
    tollingPointName: 'Wilsky Blvd On',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.34,
    collectedAmount: null
  },
  {
    id: 7,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T07:23:27Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 704945,
    plateState: 'VA',
    plateCountry: 'United States',
    vehicleId: 474,
    axleCount: 3,
    accountNumber: 530429,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1211,
    tollingPointName: 'CR 578 (County Line Rd)',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 0.49,
    collectedAmount: 0.49
  },
  {
    id: 8,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T07:56:55Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 378918,
    plateState: 'NE',
    plateCountry: 'United States',
    vehicleId: 8,
    axleCount: 2,
    accountNumber: 354973,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'DED',
    laneNumber: '06',
    laneName: '06B',
    expectedAmount: 0.27,
    collectedAmount: null
  },
  {
    id: 9,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T04:06:47Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 465200,
    plateState: 'WV',
    plateCountry: 'United States',
    vehicleId: 481,
    axleCount: 4,
    accountNumber: 228914,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1641,
    tollingPointName: 'SR570 Pace Road EB ON MP23',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.41,
    collectedAmount: 0.41
  },
  {
    id: 10,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T01:35:55Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 798463,
    plateState: 'GA',
    plateCountry: 'United States',
    vehicleId: 10,
    axleCount: 4,
    accountNumber: 90962,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2871,
    tollingPointName: 'SR23 New World Main NB MP43',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.86,
    collectedAmount: 0.86
  },
  {
    id: 11,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T18:15:07Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 367733,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 285,
    axleCount: 3,
    accountNumber: 803729,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '20',
    laneName: '20B',
    expectedAmount: 0.52,
    collectedAmount: 0.52
  },
  {
    id: 12,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T16:30:13Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 49367,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 1,
    axleCount: 2,
    accountNumber: 739634,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.85,
    collectedAmount: null
  },
  {
    id: 13,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T14:41:50Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 212684,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 807,
    axleCount: 4,
    accountNumber: 331501,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'DED',
    laneNumber: '05',
    laneName: '05Z',
    expectedAmount: 0.21,
    collectedAmount: null
  },
  {
    id: 14,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T23:51:31Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 716919,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 788,
    axleCount: 4,
    accountNumber: 635464,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '15',
    laneName: '15Z',
    expectedAmount: 0.9,
    collectedAmount: null
  },
  {
    id: 15,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T06:23:37Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 357056,
    plateState: 'NB',
    plateCountry: 'Canada',
    vehicleId: 54,
    axleCount: 3,
    accountNumber: 583358,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1211,
    tollingPointName: 'CR 578 (County Line Rd)',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.82,
    collectedAmount: 0.82
  },
  {
    id: 16,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T04:10:28Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 338482,
    plateState: 'MD',
    plateCountry: 'United States',
    vehicleId: 642,
    axleCount: 4,
    accountNumber: 160319,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '13',
    laneName: '13S',
    expectedAmount: 0.65,
    collectedAmount: null
  },
  {
    id: 17,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T07:35:59Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 80642,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 115,
    axleCount: 3,
    accountNumber: 968173,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3112,
    tollingPointName: 'CR 470',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.5,
    collectedAmount: null
  },
  {
    id: 18,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T02:03:32Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 769175,
    plateState: 'MA',
    plateCountry: 'United States',
    vehicleId: 97,
    axleCount: 3,
    accountNumber: 182958,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1841,
    tollingPointName: 'Waring Road',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.5,
    collectedAmount: null
  },
  {
    id: 19,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T19:51:46Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 341579,
    plateState: 'MT',
    plateCountry: 'United States',
    vehicleId: 550,
    axleCount: 2,
    accountNumber: 680975,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3111,
    tollingPointName: 'US 27',
    laneType: 'MB',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.5,
    collectedAmount: null
  },
  {
    id: 20,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T18:13:34Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 988748,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 983,
    axleCount: 2,
    accountNumber: 574279,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '02',
    laneName: '02B',
    expectedAmount: 0.61,
    collectedAmount: null
  },
  {
    id: 21,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T19:47:58Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 792976,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 649,
    axleCount: 4,
    accountNumber: 353844,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '15',
    laneName: '15S',
    expectedAmount: 0.25,
    collectedAmount: null
  },
  {
    id: 22,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T11:38:24Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 821309,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 424,
    axleCount: 3,
    accountNumber: 275802,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1321,
    tollingPointName: 'SR 54',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.29,
    collectedAmount: null
  },
  {
    id: 23,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T14:38:44Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 98521,
    plateState: 'TN',
    plateCountry: 'United States',
    vehicleId: 136,
    axleCount: 4,
    accountNumber: 457171,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1721,
    tollingPointName: 'Lakeland Highlands Boulevard',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00S',
    expectedAmount: 0.29,
    collectedAmount: 0.29
  },
  {
    id: 24,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T17:20:26Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 82925,
    plateState: 'MI',
    plateCountry: 'United States',
    vehicleId: 519,
    axleCount: 2,
    accountNumber: 660367,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1821,
    tollingPointName: 'South Florida Avenue',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.38,
    collectedAmount: null
  },
  {
    id: 25,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T15:12:09Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 45894,
    plateState: 'NY',
    plateCountry: 'United States',
    vehicleId: 392,
    axleCount: 4,
    accountNumber: 63809,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1403,
    tollingPointName: 'SR589 Sugarwood Expr NB MP11',
    laneType: 'ORT',
    laneNumber: '51',
    laneName: '51Z',
    expectedAmount: 0.22,
    collectedAmount: 0.22
  },
  {
    id: 26,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T15:04:27Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 912346,
    plateState: 'MO',
    plateCountry: 'United States',
    vehicleId: 885,
    axleCount: 3,
    accountNumber: 988460,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '12',
    laneName: '12B',
    expectedAmount: 0.28,
    collectedAmount: null
  },
  {
    id: 27,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T00:47:42Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 965544,
    plateState: 'AZ',
    plateCountry: 'United States',
    vehicleId: 233,
    axleCount: 4,
    accountNumber: 520288,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'MB',
    laneNumber: '03',
    laneName: '03S',
    expectedAmount: 0.98,
    collectedAmount: null
  },
  {
    id: 28,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T16:49:39Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 814570,
    plateState: 'MN',
    plateCountry: 'United States',
    vehicleId: 681,
    axleCount: 4,
    accountNumber: 254422,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1701,
    tollingPointName: 'SR 540 East-West',
    laneType: 'DED',
    laneNumber: '13',
    laneName: '13Z',
    expectedAmount: 0.32,
    collectedAmount: 0.32
  },
  {
    id: 29,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T12:46:12Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 786421,
    plateState: 'NC',
    plateCountry: 'United States',
    vehicleId: 339,
    axleCount: 4,
    accountNumber: 606571,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1404,
    tollingPointName: 'SR589 Sugarwood Expr SB MP11',
    laneType: 'ORT',
    laneNumber: '61',
    laneName: '61Z',
    expectedAmount: 0.79,
    collectedAmount: 0.79
  },
  {
    id: 30,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T05:48:36Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 397377,
    plateState: 'KS',
    plateCountry: 'United States',
    vehicleId: 817,
    axleCount: 3,
    accountNumber: 579675,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1100,
    tollingPointName: 'Oak Hammock (Northern)',
    laneType: 'MB',
    laneNumber: '08',
    laneName: '08Z',
    expectedAmount: 0.73,
    collectedAmount: null
  },
  {
    id: 31,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T05:33:14Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 937090,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 673,
    axleCount: 3,
    accountNumber: 593164,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '13',
    laneName: '13Z',
    expectedAmount: 0.97,
    collectedAmount: null
  },
  {
    id: 32,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T12:41:16Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 104344,
    plateState: 'CO',
    plateCountry: 'United States',
    vehicleId: 565,
    axleCount: 2,
    accountNumber: 135948,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1731,
    tollingPointName: 'CR 546 (Old Dixie Hwy)',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00A',
    expectedAmount: 0.71,
    collectedAmount: null
  },
  {
    id: 33,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T03:31:44Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 926741,
    plateState: 'MB',
    plateCountry: 'Canada',
    vehicleId: 153,
    axleCount: 3,
    accountNumber: 354973,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0F',
    laneName: '0FZ',
    expectedAmount: 0.52,
    collectedAmount: null
  },
  {
    id: 34,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T10:20:47Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 634470,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 800,
    axleCount: 3,
    accountNumber: 571491,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1701,
    tollingPointName: 'SR 540 East-West',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.39,
    collectedAmount: null
  },
  {
    id: 35,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T03:43:08Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 587436,
    plateState: 'MN',
    plateCountry: 'United States',
    vehicleId: 961,
    axleCount: 2,
    accountNumber: 186822,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '08',
    laneName: '08S',
    expectedAmount: 0.85,
    collectedAmount: null
  },
  {
    id: 36,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T20:22:18Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 238566,
    plateState: 'IL',
    plateCountry: 'United States',
    vehicleId: 197,
    axleCount: 2,
    accountNumber: 810885,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1211,
    tollingPointName: 'CR 578 (County Line Rd)',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.82,
    collectedAmount: 0.82
  },
  {
    id: 37,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T11:06:15Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 656314,
    plateState: 'SK',
    plateCountry: 'Canada',
    vehicleId: 378,
    axleCount: 2,
    accountNumber: 533340,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1700,
    tollingPointName: 'Central Barrier',
    laneType: 'DED',
    laneNumber: '16',
    laneName: '16Z',
    expectedAmount: 0.57,
    collectedAmount: null
  },
  {
    id: 38,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T03:07:19Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 494848,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 268,
    axleCount: 4,
    accountNumber: 778338,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '07',
    laneName: '07Z',
    expectedAmount: 0.5,
    collectedAmount: null
  },
  {
    id: 39,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T04:49:23Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 942673,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 362,
    axleCount: 4,
    accountNumber: 563597,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1221,
    tollingPointName: 'Cortez Blvd (SR 50)',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.93,
    collectedAmount: null
  },
  {
    id: 40,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T11:27:47Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 97781,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 89,
    axleCount: 2,
    accountNumber: 137396,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3112,
    tollingPointName: 'CR 470',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02S',
    expectedAmount: 0.83,
    collectedAmount: null
  },
  {
    id: 41,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T14:08:52Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 353173,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 226,
    axleCount: 2,
    accountNumber: 403892,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0A',
    laneName: '0AB',
    expectedAmount: 0.32,
    collectedAmount: null
  },
  {
    id: 42,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T13:20:36Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 646108,
    plateState: 'NV',
    plateCountry: 'United States',
    vehicleId: 882,
    axleCount: 4,
    accountNumber: 820602,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '33',
    laneName: '33Z',
    expectedAmount: 0.69,
    collectedAmount: null
  },
  {
    id: 43,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T05:22:22Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 385168,
    plateState: 'KS',
    plateCountry: 'United States',
    vehicleId: 643,
    axleCount: 4,
    accountNumber: 408263,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1700,
    tollingPointName: 'Central Barrier',
    laneType: 'MB',
    laneNumber: '17',
    laneName: '17Z',
    expectedAmount: 0.68,
    collectedAmount: 0.68
  },
  {
    id: 44,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T01:28:18Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 634282,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 992,
    axleCount: 4,
    accountNumber: 723775,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1641,
    tollingPointName: 'SR570 Pace Road EB ON MP23',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.29,
    collectedAmount: null
  },
  {
    id: 45,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T13:50:03Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 777317,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 902,
    axleCount: 3,
    accountNumber: 316238,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '22',
    laneName: '22Z',
    expectedAmount: 0.91,
    collectedAmount: null
  },
  {
    id: 46,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T04:17:16Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 115204,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 380,
    axleCount: 3,
    accountNumber: 395958,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1505,
    tollingPointName: 'SR589 Anderson Rd NBOff MP6',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.43,
    collectedAmount: null
  },
  {
    id: 47,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:07:33Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 719908,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 120,
    axleCount: 4,
    accountNumber: 712817,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'ORT',
    laneNumber: '22',
    laneName: '22Z',
    expectedAmount: 0.4,
    collectedAmount: 0.4
  },
  {
    id: 48,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T11:50:13Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 298217,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 655,
    axleCount: 2,
    accountNumber: 249214,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1502,
    tollingPointName: 'SR589 Anderson Main SB MP6',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60Z',
    expectedAmount: 0.83,
    collectedAmount: null
  },
  {
    id: 49,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T16:38:23Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 998251,
    plateState: 'WA',
    plateCountry: 'United States',
    vehicleId: 36,
    axleCount: 2,
    accountNumber: 67227,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'MB',
    laneNumber: '01',
    laneName: '01B',
    expectedAmount: 0.31,
    collectedAmount: 0.31
  },
  {
    id: 50,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T15:40:22Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 258671,
    plateState: 'VA',
    plateCountry: 'United States',
    vehicleId: 725,
    axleCount: 4,
    accountNumber: 484784,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1221,
    tollingPointName: 'Cortez Blvd (SR 50)',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02B',
    expectedAmount: 0.28,
    collectedAmount: 0.28
  },
  {
    id: 51,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T05:18:33Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 246418,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 546,
    axleCount: 4,
    accountNumber: 768967,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'COAPM',
    laneNumber: '02',
    laneName: '02D',
    expectedAmount: 0.44,
    collectedAmount: null
  },
  {
    id: 52,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T16:54:29Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 831277,
    plateState: 'WI',
    plateCountry: 'United States',
    vehicleId: 986,
    axleCount: 4,
    accountNumber: 482637,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '13',
    laneName: '13Z',
    expectedAmount: 0.46,
    collectedAmount: 0.46
  },
  {
    id: 53,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T18:24:38Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 512029,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 528,
    axleCount: 2,
    accountNumber: 723295,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1221,
    tollingPointName: 'Cortez Blvd (SR 50)',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01B',
    expectedAmount: 0.38,
    collectedAmount: null
  },
  {
    id: 54,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T21:45:00Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 996945,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 757,
    axleCount: 2,
    accountNumber: 58128,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.46,
    collectedAmount: null
  },
  {
    id: 55,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T12:31:35Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 289466,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 45,
    axleCount: 4,
    accountNumber: 787960,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04S',
    expectedAmount: 0.27,
    collectedAmount: null
  },
  {
    id: 56,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T01:25:04Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 614985,
    plateState: 'WA',
    plateCountry: 'United States',
    vehicleId: 924,
    axleCount: 3,
    accountNumber: 446047,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.48,
    collectedAmount: null
  },
  {
    id: 57,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T23:32:52Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 530751,
    plateState: 'CT',
    plateCountry: 'United States',
    vehicleId: 595,
    axleCount: 2,
    accountNumber: 526771,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.03,
    collectedAmount: null
  },
  {
    id: 58,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T01:45:43Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 509845,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 352,
    axleCount: 4,
    accountNumber: 424177,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'COAPM',
    laneNumber: '05',
    laneName: '05B',
    expectedAmount: 0.75,
    collectedAmount: null
  },
  {
    id: 59,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T13:53:00Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 735086,
    plateState: 'IL',
    plateCountry: 'United States',
    vehicleId: 733,
    axleCount: 3,
    accountNumber: 756085,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04B',
    expectedAmount: 0.9,
    collectedAmount: null
  },
  {
    id: 60,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T15:46:37Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 335370,
    plateState: 'VA',
    plateCountry: 'United States',
    vehicleId: 926,
    axleCount: 4,
    accountNumber: 654946,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1721,
    tollingPointName: 'Lakeland Highlands Boulevard',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01B',
    expectedAmount: 0.43,
    collectedAmount: 0.43
  },
  {
    id: 61,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T06:33:26Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 776488,
    plateState: 'WI',
    plateCountry: 'United States',
    vehicleId: 460,
    axleCount: 4,
    accountNumber: 34071,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1821,
    tollingPointName: 'South Florida Avenue',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03S',
    expectedAmount: 0.57,
    collectedAmount: null
  },
  {
    id: 62,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T05:31:52Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 656755,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 496,
    axleCount: 4,
    accountNumber: 207603,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'DED',
    laneNumber: '06',
    laneName: '06S',
    expectedAmount: 0.18,
    collectedAmount: null
  },
  {
    id: 63,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T06:15:10Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 935460,
    plateState: 'GA',
    plateCountry: 'United States',
    vehicleId: 989,
    axleCount: 2,
    accountNumber: 408190,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1851,
    tollingPointName: 'Airport Road',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03B',
    expectedAmount: 0.77,
    collectedAmount: null
  },
  {
    id: 64,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T17:10:16Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 963995,
    plateState: 'NV',
    plateCountry: 'United States',
    vehicleId: 677,
    axleCount: 3,
    accountNumber: 907093,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.16,
    collectedAmount: null
  },
  {
    id: 65,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T06:12:05Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 100778,
    plateState: 'PA',
    plateCountry: 'United States',
    vehicleId: 930,
    axleCount: 2,
    accountNumber: 977933,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1403,
    tollingPointName: 'SR589 Sugarwood Expr NB MP11',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.87,
    collectedAmount: null
  },
  {
    id: 66,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T06:41:00Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 296124,
    plateState: 'NY',
    plateCountry: 'United States',
    vehicleId: 979,
    axleCount: 2,
    accountNumber: 636212,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'MB',
    laneNumber: '22',
    laneName: '22Z',
    expectedAmount: 0.64,
    collectedAmount: 0.64
  },
  {
    id: 67,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T11:15:40Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 722405,
    plateState: 'MB',
    plateCountry: 'Canada',
    vehicleId: 421,
    axleCount: 2,
    accountNumber: 113020,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '05',
    laneName: '05Z',
    expectedAmount: 0.68,
    collectedAmount: null
  },
  {
    id: 68,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T10:23:52Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 906834,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 147,
    axleCount: 2,
    accountNumber: 606674,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1721,
    tollingPointName: 'Lakeland Highlands Boulevard',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.96,
    collectedAmount: null
  },
  {
    id: 69,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:09:41Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 722405,
    plateState: 'MB',
    plateCountry: 'Canada',
    vehicleId: 421,
    axleCount: 2,
    accountNumber: 113020,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1321,
    tollingPointName: 'SR 54',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03B',
    expectedAmount: 0.53,
    collectedAmount: 0.53
  },
  {
    id: 70,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:03:34Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 954660,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 296,
    axleCount: 2,
    accountNumber: 581786,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3110,
    tollingPointName: 'Clermont',
    laneType: 'MB',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.04,
    collectedAmount: 0.04
  },
  {
    id: 71,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T19:20:57Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 229642,
    plateState: 'PA',
    plateCountry: 'United States',
    vehicleId: 67,
    axleCount: 4,
    accountNumber: 570645,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1821,
    tollingPointName: 'South Florida Avenue',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.22,
    collectedAmount: null
  },
  {
    id: 72,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T17:56:16Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 895419,
    plateState: 'MO',
    plateCountry: 'United States',
    vehicleId: 813,
    axleCount: 3,
    accountNumber: 565255,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1841,
    tollingPointName: 'Waring Road',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02A',
    expectedAmount: 0.12,
    collectedAmount: null
  },
  {
    id: 73,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T19:56:13Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 616419,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 404,
    axleCount: 3,
    accountNumber: 63809,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '22',
    laneName: '22A',
    expectedAmount: 0.77,
    collectedAmount: null
  },
  {
    id: 74,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T00:16:44Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 364724,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 267,
    axleCount: 4,
    accountNumber: 451209,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '55',
    laneName: '55Z',
    expectedAmount: 0.97,
    collectedAmount: null
  },
  {
    id: 75,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T18:53:39Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 455345,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 276,
    axleCount: 3,
    accountNumber: 801642,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1221,
    tollingPointName: 'Cortez Blvd (SR 50)',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.85,
    collectedAmount: null
  },
  {
    id: 76,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T03:27:55Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 337180,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 465,
    axleCount: 3,
    accountNumber: 719807,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '99',
    laneName: '99D',
    expectedAmount: 0.35,
    collectedAmount: null
  },
  {
    id: 77,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T20:02:06Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 89303,
    plateState: 'IN',
    plateCountry: 'United States',
    vehicleId: 867,
    axleCount: 4,
    accountNumber: 805472,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1100,
    tollingPointName: 'Oak Hammock (Northern)',
    laneType: 'ORT',
    laneNumber: '02',
    laneName: '02A',
    expectedAmount: 0.69,
    collectedAmount: null
  },
  {
    id: 78,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T00:53:12Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 926741,
    plateState: 'MB',
    plateCountry: 'Canada',
    vehicleId: 153,
    axleCount: 3,
    accountNumber: 354973,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '06',
    laneName: '06Z',
    expectedAmount: 0.18,
    collectedAmount: 0.18
  },
  {
    id: 79,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T15:54:47Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 113530,
    plateState: 'NB',
    plateCountry: 'Canada',
    vehicleId: 851,
    axleCount: 2,
    accountNumber: 547960,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '13',
    laneName: '13Z',
    expectedAmount: 0.35,
    collectedAmount: null
  },
  {
    id: 80,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T18:15:14Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 439442,
    plateState: 'AK',
    plateCountry: 'United States',
    vehicleId: 434,
    axleCount: 2,
    accountNumber: 189992,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '55',
    laneName: '55Z',
    expectedAmount: 0.22,
    collectedAmount: null
  },
  {
    id: 81,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T21:38:24Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 941885,
    plateState: 'NC',
    plateCountry: 'United States',
    vehicleId: 776,
    axleCount: 3,
    accountNumber: 151356,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'ORT',
    laneNumber: '13',
    laneName: '13Z',
    expectedAmount: 0.95,
    collectedAmount: null
  },
  {
    id: 82,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T13:37:35Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 874025,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 195,
    axleCount: 3,
    accountNumber: 758085,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '55',
    laneName: '55B',
    expectedAmount: 0.5,
    collectedAmount: 0.5
  },
  {
    id: 83,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T08:59:40Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 263643,
    plateState: 'MS',
    plateCountry: 'United States',
    vehicleId: 31,
    axleCount: 4,
    accountNumber: 615138,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3116,
    tollingPointName: 'SR91 Hancock Road SBOn MP278',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.53,
    collectedAmount: 0.53
  },
  {
    id: 84,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T00:23:37Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 472662,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 98,
    axleCount: 3,
    accountNumber: 201244,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1511,
    tollingPointName: 'SR589 Waters Av SBOn MP6',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60D',
    expectedAmount: 0.11,
    collectedAmount: 0.11
  },
  {
    id: 85,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T01:00:35Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 209118,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 973,
    axleCount: 3,
    accountNumber: 62597,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1831,
    tollingPointName: 'Harden Blvd',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03A',
    expectedAmount: 0.91,
    collectedAmount: 0.91
  },
  {
    id: 86,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T10:26:05Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 172733,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 124,
    axleCount: 4,
    accountNumber: 526771,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1731,
    tollingPointName: 'CR 546 (Old Dixie Hwy)',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.36,
    collectedAmount: null
  },
  {
    id: 87,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T03:14:53Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 172937,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 129,
    axleCount: 3,
    accountNumber: 574279,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '03',
    laneName: '03B',
    expectedAmount: 0.44,
    collectedAmount: null
  },
  {
    id: 88,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T21:21:39Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 793138,
    plateState: 'CT',
    plateCountry: 'United States',
    vehicleId: 313,
    axleCount: 3,
    accountNumber: 303602,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.96,
    collectedAmount: 0.96
  },
  {
    id: 89,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T13:04:07Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 659093,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 354,
    axleCount: 2,
    accountNumber: 510795,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1100,
    tollingPointName: 'Oak Hammock (Northern)',
    laneType: 'ORT',
    laneNumber: '09',
    laneName: '09S',
    expectedAmount: 0.37,
    collectedAmount: null
  },
  {
    id: 90,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T22:05:07Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 320908,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 668,
    axleCount: 3,
    accountNumber: 975722,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02B',
    expectedAmount: 0.06,
    collectedAmount: null
  },
  {
    id: 91,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T04:32:30Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 779515,
    plateState: 'WA',
    plateCountry: 'United States',
    vehicleId: 957,
    axleCount: 4,
    accountNumber: 520288,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '13',
    laneName: '13Z',
    expectedAmount: 0.17,
    collectedAmount: 0.17
  },
  {
    id: 92,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T06:13:34Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 565429,
    plateState: 'KY',
    plateCountry: 'United States',
    vehicleId: 627,
    axleCount: 2,
    accountNumber: 632645,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2832,
    tollingPointName: 'SR23 Trail Ridge Main SB MP33',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.17,
    collectedAmount: 0.17
  },
  {
    id: 93,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T18:01:59Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 261644,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 654,
    axleCount: 2,
    accountNumber: 803947,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '12',
    laneName: '12A',
    expectedAmount: 0.55,
    collectedAmount: null
  },
  {
    id: 94,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T06:36:53Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '',
    licensePlate: 454204,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 221,
    axleCount: 4,
    accountNumber: 705865,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1721,
    tollingPointName: 'Lakeland Highlands Boulevard',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02B',
    expectedAmount: 0.8,
    collectedAmount: null
  },
  {
    id: 95,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T01:52:25Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 590160,
    plateState: 'TN',
    plateCountry: 'United States',
    vehicleId: 351,
    axleCount: 3,
    accountNumber: 441813,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1701,
    tollingPointName: 'SR 540 East-West',
    laneType: 'COAPM',
    laneNumber: '14',
    laneName: '14B',
    expectedAmount: 0.82,
    collectedAmount: null
  },
  {
    id: 96,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T17:23:22Z',
    receivedDateTime: '2020-04-28 17:27:37 -0400',
    resolutionDateTime: '2020-04-28 17:27:37 -0400',
    licensePlate: 190194,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 619,
    axleCount: 3,
    accountNumber: 640989,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1731,
    tollingPointName: 'CR 546 (Old Dixie Hwy)',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03Z',
    expectedAmount: 0.75,
    collectedAmount: 0.75
  },
  {
    id: 97,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T17:12:57Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 777543,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 758,
    axleCount: 4,
    accountNumber: 425223,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1421,
    tollingPointName: 'SR589 Gunn Hwy NB On MP9',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.99,
    collectedAmount: null
  },
  {
    id: 98,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T18:40:42Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 643873,
    plateState: 'CO',
    plateCountry: 'United States',
    vehicleId: 199,
    axleCount: 3,
    accountNumber: 906011,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1100,
    tollingPointName: 'Oak Hammock (Northern)',
    laneType: 'MB',
    laneNumber: '66',
    laneName: '66B',
    expectedAmount: 0.93,
    collectedAmount: null
  },
  {
    id: 99,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T13:49:34Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 89303,
    plateState: 'IN',
    plateCountry: 'United States',
    vehicleId: 867,
    axleCount: 4,
    accountNumber: 805472,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3115,
    tollingPointName: 'SR91 Hancock Road NBOff MP278',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00S',
    expectedAmount: 0.83,
    collectedAmount: null
  },
  {
    id: 100,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T07:03:03Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 667653,
    plateState: 'TN',
    plateCountry: 'United States',
    vehicleId: 458,
    axleCount: 4,
    accountNumber: 72379,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1403,
    tollingPointName: 'SR589 Sugarwood Expr NB MP11',
    laneType: 'ORT',
    laneNumber: '51',
    laneName: '51S',
    expectedAmount: 0.81,
    collectedAmount: null
  },
  {
    id: 101,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T10:09:40Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 965943,
    plateState: 'MI',
    plateCountry: 'United States',
    vehicleId: 127,
    axleCount: 4,
    accountNumber: 667117,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2871,
    tollingPointName: 'SR23 New World Main NB MP43',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00S',
    expectedAmount: 0.9,
    collectedAmount: null
  },
  {
    id: 102,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T17:45:30Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 799149,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 497,
    axleCount: 2,
    accountNumber: 709655,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '14',
    laneName: '14Z',
    expectedAmount: 0.11,
    collectedAmount: null
  },
  {
    id: 103,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T05:16:48Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 396331,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 808,
    axleCount: 2,
    accountNumber: 476191,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0C',
    laneName: '0CS',
    expectedAmount: 0.49,
    collectedAmount: 0.49
  },
  {
    id: 104,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T05:55:06Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 880387,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 178,
    axleCount: 4,
    accountNumber: 780650,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1851,
    tollingPointName: 'Airport Road',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01B',
    expectedAmount: 0.13,
    collectedAmount: null
  },
  {
    id: 105,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T00:13:37Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 403443,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 544,
    axleCount: 2,
    accountNumber: 828847,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2862,
    tollingPointName: 'SR23 Normandy Main SB MP42',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00S',
    expectedAmount: 0.84,
    collectedAmount: null
  },
  {
    id: 106,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T22:59:25Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 801342,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 270,
    axleCount: 3,
    accountNumber: 977933,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1831,
    tollingPointName: 'Harden Blvd',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 1,
    collectedAmount: null
  },
  {
    id: 107,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T20:43:48Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 333880,
    plateState: 'CT',
    plateCountry: 'United States',
    vehicleId: 769,
    axleCount: 2,
    accountNumber: 369496,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '11',
    laneName: '11B',
    expectedAmount: 0.63,
    collectedAmount: null
  },
  {
    id: 108,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T15:15:54Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 92297,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 822,
    axleCount: 3,
    accountNumber: 369496,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'DED',
    laneNumber: '06',
    laneName: '06B',
    expectedAmount: 0.61,
    collectedAmount: 0.61
  },
  {
    id: 109,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T00:33:51Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 12447,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 712,
    axleCount: 2,
    accountNumber: 327099,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'ADM',
    laneNumber: '0C',
    laneName: '0CS',
    expectedAmount: 0.74,
    collectedAmount: null
  },
  {
    id: 110,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T23:23:18Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 596409,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 618,
    axleCount: 2,
    accountNumber: 572668,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 0.67,
    collectedAmount: null
  },
  {
    id: 111,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T04:36:17Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 356368,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 443,
    axleCount: 4,
    accountNumber: 72488,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 0.29,
    collectedAmount: null
  },
  {
    id: 112,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T20:13:37Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 293168,
    plateState: 'NC',
    plateCountry: 'United States',
    vehicleId: 163,
    axleCount: 3,
    accountNumber: 42267,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'MB',
    laneNumber: '22',
    laneName: '22Z',
    expectedAmount: 0.73,
    collectedAmount: null
  },
  {
    id: 113,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T23:43:53Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 584978,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 795,
    axleCount: 4,
    accountNumber: 72602,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1831,
    tollingPointName: 'Harden Blvd',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03S',
    expectedAmount: 0.03,
    collectedAmount: null
  },
  {
    id: 114,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:50:14Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 537132,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 315,
    axleCount: 4,
    accountNumber: 216658,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.33,
    collectedAmount: 0.33
  },
  {
    id: 115,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T09:56:21Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 717129,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 888,
    axleCount: 2,
    accountNumber: 688542,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1831,
    tollingPointName: 'Harden Blvd',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 0.29,
    collectedAmount: null
  },
  {
    id: 116,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T19:15:19Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 247504,
    plateState: 'DE',
    plateCountry: 'United States',
    vehicleId: 26,
    axleCount: 2,
    accountNumber: 203039,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1402,
    tollingPointName: 'SR589 Sugarwood Main SB MP11',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60B',
    expectedAmount: 0.72,
    collectedAmount: null
  },
  {
    id: 117,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T14:41:03Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 677121,
    plateState: 'MD',
    plateCountry: 'United States',
    vehicleId: 541,
    axleCount: 3,
    accountNumber: 641903,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.68,
    collectedAmount: null
  },
  {
    id: 118,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T10:58:48Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 659325,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 937,
    axleCount: 2,
    accountNumber: 719807,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04B',
    expectedAmount: 0.89,
    collectedAmount: 0.89
  },
  {
    id: 119,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T03:43:24Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 886414,
    plateState: 'LA',
    plateCountry: 'United States',
    vehicleId: 457,
    axleCount: 4,
    accountNumber: 170926,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '05',
    laneName: '05Z',
    expectedAmount: 0.01,
    collectedAmount: 0.01
  },
  {
    id: 120,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T22:30:59Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 400965,
    plateState: 'OH',
    plateCountry: 'United States',
    vehicleId: 3,
    axleCount: 2,
    accountNumber: 176254,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '03',
    laneName: '03Z',
    expectedAmount: 0.49,
    collectedAmount: null
  },
  {
    id: 121,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T21:31:40Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 292525,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 952,
    axleCount: 2,
    accountNumber: 499576,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1831,
    tollingPointName: 'Harden Blvd',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02A',
    expectedAmount: 0.11,
    collectedAmount: 0.11
  },
  {
    id: 122,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T02:17:12Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 482690,
    plateState: 'CO',
    plateCountry: 'United States',
    vehicleId: 37,
    axleCount: 2,
    accountNumber: 849562,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'DED',
    laneNumber: '09',
    laneName: '09Z',
    expectedAmount: 0.71,
    collectedAmount: null
  },
  {
    id: 123,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T02:45:37Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 253882,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 724,
    axleCount: 4,
    accountNumber: 373805,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1700,
    tollingPointName: 'Central Barrier',
    laneType: 'DED',
    laneNumber: '09',
    laneName: '09B',
    expectedAmount: 0.1,
    collectedAmount: null
  },
  {
    id: 124,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T05:41:08Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 826325,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 585,
    axleCount: 3,
    accountNumber: 69745,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '22',
    laneName: '22Z',
    expectedAmount: 0.47,
    collectedAmount: 0.47
  },
  {
    id: 125,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T06:51:34Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 226857,
    plateState: 'MB',
    plateCountry: 'Canada',
    vehicleId: 393,
    axleCount: 3,
    accountNumber: 218528,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1400,
    tollingPointName: 'Sugarwood Mainline',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.63,
    collectedAmount: 0.63
  },
  {
    id: 126,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T13:19:59Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 896663,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 271,
    axleCount: 2,
    accountNumber: 790773,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1221,
    tollingPointName: 'Cortez Blvd (SR 50)',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.89,
    collectedAmount: null
  },
  {
    id: 127,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T03:11:07Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 900320,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 692,
    axleCount: 4,
    accountNumber: 489935,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0C',
    laneName: '0CS',
    expectedAmount: 0.66,
    collectedAmount: null
  },
  {
    id: 128,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T19:14:06Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 587436,
    plateState: 'MN',
    plateCountry: 'United States',
    vehicleId: 961,
    axleCount: 2,
    accountNumber: 186822,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1211,
    tollingPointName: 'CR 578 (County Line Rd)',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01S',
    expectedAmount: 0.19,
    collectedAmount: null
  },
  {
    id: 129,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T17:19:35Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 856389,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 966,
    axleCount: 3,
    accountNumber: 922477,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1321,
    tollingPointName: 'SR 54',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 0.84,
    collectedAmount: null
  },
  {
    id: 130,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T03:55:32Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 712380,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 131,
    axleCount: 4,
    accountNumber: 209861,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '99',
    laneName: '99A',
    expectedAmount: 0.04,
    collectedAmount: null
  },
  {
    id: 131,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T09:45:12Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 911998,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 82,
    axleCount: 4,
    accountNumber: 225010,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0G',
    laneName: '0GS',
    expectedAmount: 0.45,
    collectedAmount: null
  },
  {
    id: 132,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:18:04Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 399464,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 965,
    axleCount: 3,
    accountNumber: 611117,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1821,
    tollingPointName: 'South Florida Avenue',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01Z',
    expectedAmount: 0.52,
    collectedAmount: 0.52
  },
  {
    id: 133,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T08:42:04Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 812782,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 19,
    axleCount: 3,
    accountNumber: 972853,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '07',
    laneName: '07Z',
    expectedAmount: 0.35,
    collectedAmount: null
  },
  {
    id: 134,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T22:02:33Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 572071,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 857,
    axleCount: 3,
    accountNumber: 142438,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '14',
    laneName: '14Z',
    expectedAmount: 0.18,
    collectedAmount: null
  },
  {
    id: 135,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T05:02:36Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 931396,
    plateState: 'SK',
    plateCountry: 'Canada',
    vehicleId: 401,
    axleCount: 2,
    accountNumber: 212491,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1321,
    tollingPointName: 'SR 54',
    laneType: 'DED',
    laneNumber: '02',
    laneName: '02Z',
    expectedAmount: 0.32,
    collectedAmount: null
  },
  {
    id: 136,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T07:46:07Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 241841,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 518,
    axleCount: 2,
    accountNumber: 819219,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '03',
    laneName: '03B',
    expectedAmount: 0.77,
    collectedAmount: null
  },
  {
    id: 137,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T13:34:23Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 453514,
    plateState: 'AZ',
    plateCountry: 'United States',
    vehicleId: 916,
    axleCount: 2,
    accountNumber: 664359,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'ORT',
    laneNumber: '02',
    laneName: '02B',
    expectedAmount: 0.37,
    collectedAmount: null
  },
  {
    id: 138,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T06:59:36Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 858202,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 181,
    axleCount: 4,
    accountNumber: 521530,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.46,
    collectedAmount: 0.46
  },
  {
    id: 139,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T18:00:22Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 164860,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 175,
    axleCount: 2,
    accountNumber: 199154,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04D',
    expectedAmount: 0.14,
    collectedAmount: null
  },
  {
    id: 140,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T20:39:53Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 172733,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 124,
    axleCount: 3,
    accountNumber: 526771,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'DED',
    laneNumber: '09',
    laneName: '09A',
    expectedAmount: 0.63,
    collectedAmount: null
  },
  {
    id: 141,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T13:37:46Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 814570,
    plateState: 'MN',
    plateCountry: 'United States',
    vehicleId: 681,
    axleCount: 3,
    accountNumber: 254422,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1701,
    tollingPointName: 'SR 540 East-West',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.4,
    collectedAmount: null
  },
  {
    id: 142,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T18:34:16Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 909865,
    plateState: 'GA',
    plateCountry: 'United States',
    vehicleId: 737,
    axleCount: 2,
    accountNumber: 754025,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'ORT',
    laneNumber: '13',
    laneName: '13B',
    expectedAmount: 0.64,
    collectedAmount: null
  },
  {
    id: 143,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T22:06:14Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 587436,
    plateState: 'MN',
    plateCountry: 'United States',
    vehicleId: 961,
    axleCount: 2,
    accountNumber: 186822,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1512,
    tollingPointName: 'SR589 Waters Av NBOff MP6',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.44,
    collectedAmount: null
  },
  {
    id: 144,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T00:04:53Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 45894,
    plateState: 'NY',
    plateCountry: 'United States',
    vehicleId: 392,
    axleCount: 4,
    accountNumber: 63809,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1522,
    tollingPointName: 'SR589 Hillsboroug Av NBOff MP4',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50B',
    expectedAmount: 0.84,
    collectedAmount: null
  },
  {
    id: 145,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T08:13:22Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 509845,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 352,
    axleCount: 3,
    accountNumber: 424177,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3111,
    tollingPointName: 'US 27',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00S',
    expectedAmount: 0.6,
    collectedAmount: 0.6
  },
  {
    id: 146,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T16:53:26Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 840774,
    plateState: 'NE',
    plateCountry: 'United States',
    vehicleId: 810,
    axleCount: 3,
    accountNumber: 878995,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1400,
    tollingPointName: 'Sugarwood Mainline',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.37,
    collectedAmount: null
  },
  {
    id: 147,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:44:46Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 644312,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 717,
    axleCount: 4,
    accountNumber: 63984,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '06',
    laneName: '06B',
    expectedAmount: 0.99,
    collectedAmount: 0.99
  },
  {
    id: 148,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T04:46:23Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 424342,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 744,
    axleCount: 3,
    accountNumber: 209861,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04B',
    expectedAmount: 0.23,
    collectedAmount: null
  },
  {
    id: 149,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T14:46:52Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 694920,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 62,
    axleCount: 2,
    accountNumber: 182959,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '14',
    laneName: '14Z',
    expectedAmount: 0.03,
    collectedAmount: null
  },
  {
    id: 150,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T02:53:41Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 653653,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 537,
    axleCount: 3,
    accountNumber: 790773,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1502,
    tollingPointName: 'SR589 Anderson Main SB MP6',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60D',
    expectedAmount: 0.61,
    collectedAmount: null
  },
  {
    id: 151,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T16:15:06Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 963860,
    plateState: 'SK',
    plateCountry: 'Canada',
    vehicleId: 310,
    axleCount: 4,
    accountNumber: 333440,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'DED',
    laneNumber: '10',
    laneName: '10Z',
    expectedAmount: 0.84,
    collectedAmount: null
  },
  {
    id: 152,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T21:38:59Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 527553,
    plateState: 'SC',
    plateCountry: 'United States',
    vehicleId: 942,
    axleCount: 2,
    accountNumber: 752029,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.04,
    collectedAmount: null
  },
  {
    id: 153,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T10:08:57Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 825824,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 900,
    axleCount: 2,
    accountNumber: 288900,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1331,
    tollingPointName: 'Van Dyke Road',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01D',
    expectedAmount: 0.04,
    collectedAmount: null
  },
  {
    id: 154,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T21:00:03Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 839625,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 428,
    axleCount: 2,
    accountNumber: 241178,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1402,
    tollingPointName: 'SR589 Sugarwood Main SB MP11',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60B',
    expectedAmount: 0.85,
    collectedAmount: 0.85
  },
  {
    id: 155,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T01:46:17Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 558820,
    plateState: 'NM',
    plateCountry: 'United States',
    vehicleId: 356,
    axleCount: 3,
    accountNumber: 383596,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'DED',
    laneNumber: '06',
    laneName: '06S',
    expectedAmount: 0.67,
    collectedAmount: null
  },
  {
    id: 156,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T15:39:06Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 975576,
    plateState: 'VA',
    plateCountry: 'United States',
    vehicleId: 400,
    axleCount: 2,
    accountNumber: 631927,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04D',
    expectedAmount: 0.73,
    collectedAmount: 0.73
  },
  {
    id: 157,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T08:20:02Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 900320,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 692,
    axleCount: 2,
    accountNumber: 489935,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1221,
    tollingPointName: 'Cortez Blvd (SR 50)',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.05,
    collectedAmount: null
  },
  {
    id: 158,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T00:46:56Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 808623,
    plateState: 'IL',
    plateCountry: 'United States',
    vehicleId: 278,
    axleCount: 3,
    accountNumber: 85254,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0A',
    laneName: '0AZ',
    expectedAmount: 0.56,
    collectedAmount: 0.56
  },
  {
    id: 159,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T18:26:57Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 629254,
    plateState: 'FL',
    plateCountry: 'United States',
    vehicleId: 651,
    axleCount: 2,
    accountNumber: 862699,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '99',
    laneName: '99Z',
    expectedAmount: 0.44,
    collectedAmount: null
  },
  {
    id: 160,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T21:20:29Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 233082,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 18,
    axleCount: 4,
    accountNumber: 454413,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1711,
    tollingPointName: 'SR 540 North-South',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01B',
    expectedAmount: 0.15,
    collectedAmount: null
  },
  {
    id: 161,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T22:55:28Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 609492,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 325,
    axleCount: 4,
    accountNumber: 622946,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2862,
    tollingPointName: 'SR23 Normandy Main SB MP42',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60Z',
    expectedAmount: 0.78,
    collectedAmount: 0.78
  },
  {
    id: 162,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T12:44:06Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 90036,
    plateState: 'KS',
    plateCountry: 'United States',
    vehicleId: 28,
    axleCount: 2,
    accountNumber: 849871,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '03',
    laneName: '03B',
    expectedAmount: 0.38,
    collectedAmount: null
  },
  {
    id: 163,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T23:29:06Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 708967,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 662,
    axleCount: 2,
    accountNumber: 570645,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.89,
    collectedAmount: null
  },
  {
    id: 164,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T02:07:49Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 82925,
    plateState: 'MI',
    plateCountry: 'United States',
    vehicleId: 519,
    axleCount: 3,
    accountNumber: 660367,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1522,
    tollingPointName: 'SR589 Hillsboroug Av NBOff MP4',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.42,
    collectedAmount: 0.42
  },
  {
    id: 165,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T06:20:09Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 200801,
    plateState: 'MD',
    plateCountry: 'United States',
    vehicleId: 644,
    axleCount: 4,
    accountNumber: 123884,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1321,
    tollingPointName: 'SR 54',
    laneType: 'DED',
    laneNumber: '04',
    laneName: '04Z',
    expectedAmount: 0.59,
    collectedAmount: null
  },
  {
    id: 166,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T07:57:59Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 305505,
    plateState: 'MB',
    plateCountry: 'Canada',
    vehicleId: 823,
    axleCount: 2,
    accountNumber: 112626,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'COAPM',
    laneNumber: '02',
    laneName: '02A',
    expectedAmount: 0.16,
    collectedAmount: null
  },
  {
    id: 167,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T23:23:15Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 648873,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 283,
    axleCount: 3,
    accountNumber: 819219,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3120,
    tollingPointName: 'SR91 WntrGrdn SR50 NBOff MP272',
    laneType: 'DED',
    laneNumber: '03',
    laneName: '03S',
    expectedAmount: 0.29,
    collectedAmount: null
  },
  {
    id: 168,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T22:54:32Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 719908,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 120,
    axleCount: 4,
    accountNumber: 712817,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0F',
    laneName: '0FZ',
    expectedAmount: 0.23,
    collectedAmount: null
  },
  {
    id: 169,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T06:17:17Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 107466,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 406,
    axleCount: 3,
    accountNumber: 275802,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1841,
    tollingPointName: 'Waring Road',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00A',
    expectedAmount: 0.74,
    collectedAmount: 0.74
  },
  {
    id: 170,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T18:02:11Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 253882,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 724,
    axleCount: 3,
    accountNumber: 373805,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3115,
    tollingPointName: 'SR91 Hancock Road NBOff MP278',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.12,
    collectedAmount: null
  },
  {
    id: 171,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T14:33:24Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 793138,
    plateState: 'CT',
    plateCountry: 'United States',
    vehicleId: 313,
    axleCount: 3,
    accountNumber: 303602,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1701,
    tollingPointName: 'SR 540 East-West',
    laneType: 'DED',
    laneNumber: '13',
    laneName: '13D',
    expectedAmount: 0.26,
    collectedAmount: 0.26
  },
  {
    id: 172,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T18:43:03Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 804042,
    plateState: 'NC',
    plateCountry: 'United States',
    vehicleId: 994,
    axleCount: 4,
    accountNumber: 331501,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1211,
    tollingPointName: 'CR 578 (County Line Rd)',
    laneType: 'COAPM',
    laneNumber: '01',
    laneName: '01B',
    expectedAmount: 0.63,
    collectedAmount: 0.63
  },
  {
    id: 173,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T09:20:01Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 359413,
    plateState: 'SK',
    plateCountry: 'Canada',
    vehicleId: 391,
    axleCount: 2,
    accountNumber: 608017,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1700,
    tollingPointName: 'Central Barrier',
    laneType: 'MB',
    laneNumber: '18',
    laneName: '18A',
    expectedAmount: 0.03,
    collectedAmount: null
  },
  {
    id: 174,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T03:32:09Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 774761,
    plateState: 'IN',
    plateCountry: 'United States',
    vehicleId: 609,
    axleCount: 4,
    accountNumber: 756040,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'DED',
    laneNumber: '10',
    laneName: '10B',
    expectedAmount: 0.13,
    collectedAmount: null
  },
  {
    id: 175,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T02:49:09Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 562972,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 214,
    axleCount: 4,
    accountNumber: 819219,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.68,
    collectedAmount: null
  },
  {
    id: 176,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T11:28:35Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 39745,
    plateState: 'WI',
    plateCountry: 'United States',
    vehicleId: 814,
    axleCount: 3,
    accountNumber: 169094,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.01,
    collectedAmount: 0.01
  },
  {
    id: 177,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T18:54:25Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 541936,
    plateState: 'NC',
    plateCountry: 'United States',
    vehicleId: 756,
    axleCount: 4,
    accountNumber: 534622,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1100,
    tollingPointName: 'Oak Hammock (Northern)',
    laneType: 'MB',
    laneNumber: '66',
    laneName: '66D',
    expectedAmount: 0.46,
    collectedAmount: 0.46
  },
  {
    id: 178,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T01:43:40Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 27855,
    plateState: 'NY',
    plateCountry: 'United States',
    vehicleId: 978,
    axleCount: 4,
    accountNumber: 484784,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1512,
    tollingPointName: 'SR589 Waters Av NBOff MP6',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50Z',
    expectedAmount: 0.73,
    collectedAmount: null
  },
  {
    id: 179,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T05:36:23Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 264275,
    plateState: 'NL',
    plateCountry: 'Canada',
    vehicleId: 86,
    axleCount: 2,
    accountNumber: 524201,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'MB',
    laneNumber: '14',
    laneName: '14Z',
    expectedAmount: 0.55,
    collectedAmount: null
  },
  {
    id: 180,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T14:49:19Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 390733,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 327,
    axleCount: 3,
    accountNumber: 506987,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1402,
    tollingPointName: 'SR589 Sugarwood Main SB MP11',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60Z',
    expectedAmount: 0.48,
    collectedAmount: null
  },
  {
    id: 181,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T14:02:32Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 424794,
    plateState: 'OK',
    plateCountry: 'United States',
    vehicleId: 790,
    axleCount: 2,
    accountNumber: 636098,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2872,
    tollingPointName: 'SR23 New World Main SB MP43',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.25,
    collectedAmount: null
  },
  {
    id: 182,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T17:44:30Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 70032,
    plateState: 'BC',
    plateCountry: 'Canada',
    vehicleId: 372,
    axleCount: 2,
    accountNumber: 632645,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1701,
    tollingPointName: 'SR 540 East-West',
    laneType: 'DED',
    laneNumber: '13',
    laneName: '13B',
    expectedAmount: 0.16,
    collectedAmount: null
  },
  {
    id: 183,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T05:41:59Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 333880,
    plateState: 'CT',
    plateCountry: 'United States',
    vehicleId: 769,
    axleCount: 3,
    accountNumber: 369496,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1800,
    tollingPointName: 'Western Barrier',
    laneType: 'MB',
    laneNumber: '15',
    laneName: '15B',
    expectedAmount: 0.03,
    collectedAmount: null
  },
  {
    id: 184,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T16:49:02Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 352753,
    plateState: 'ON',
    plateCountry: 'Canada',
    vehicleId: 132,
    axleCount: 2,
    accountNumber: 257218,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1851,
    tollingPointName: 'Airport Road',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03B',
    expectedAmount: 0.31,
    collectedAmount: null
  },
  {
    id: 185,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T01:21:35Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 576063,
    plateState: 'TX',
    plateCountry: 'United States',
    vehicleId: 853,
    axleCount: 3,
    accountNumber: 785859,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1505,
    tollingPointName: 'SR589 Anderson Rd NBOff MP6',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50B',
    expectedAmount: 0.26,
    collectedAmount: null
  },
  {
    id: 186,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T12:05:35Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 614135,
    plateState: 'NC',
    plateCountry: 'United States',
    vehicleId: 55,
    axleCount: 2,
    accountNumber: 726428,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '08',
    laneName: '08B',
    expectedAmount: 0.66,
    collectedAmount: null
  },
  {
    id: 187,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T04:04:54Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 122251,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 204,
    axleCount: 2,
    accountNumber: 474983,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3120,
    tollingPointName: 'SR91 WntrGrdn SR50 NBOff MP272',
    laneType: 'MB',
    laneNumber: '01',
    laneName: '01S',
    expectedAmount: 0.48,
    collectedAmount: null
  },
  {
    id: 188,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T21:19:34Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 886952,
    plateState: 'DC',
    plateCountry: 'United States',
    vehicleId: 57,
    axleCount: 2,
    accountNumber: 57101,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3112,
    tollingPointName: 'CR 470',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03A',
    expectedAmount: 0.77,
    collectedAmount: null
  },
  {
    id: 189,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T07:36:01Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 668796,
    plateState: 'NY',
    plateCountry: 'United States',
    vehicleId: 742,
    axleCount: 3,
    accountNumber: 29059,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '0E',
    laneName: '0EB',
    expectedAmount: 0.48,
    collectedAmount: null
  },
  {
    id: 190,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T12:39:48Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 479080,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 869,
    axleCount: 4,
    accountNumber: 723775,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2832,
    tollingPointName: 'SR23 Trail Ridge Main SB MP33',
    laneType: 'ORT',
    laneNumber: '60',
    laneName: '60Z',
    expectedAmount: 0.66,
    collectedAmount: null
  },
  {
    id: 191,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T23:49:30Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 526109,
    plateState: 'NY',
    plateCountry: 'United States',
    vehicleId: 572,
    axleCount: 4,
    accountNumber: 207675,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3112,
    tollingPointName: 'CR 470',
    laneType: 'COAPM',
    laneNumber: '03',
    laneName: '03Z',
    expectedAmount: 0.07,
    collectedAmount: null
  },
  {
    id: 192,
    collectionStatus: 'In Progress',
    transactionDateTime: '2020-04-01T12:14:36Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 428423,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 633,
    axleCount: 3,
    accountNumber: 320591,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '88',
    laneName: '88B',
    expectedAmount: 0.77,
    collectedAmount: null
  },
  {
    id: 193,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T09:09:00Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 726445,
    plateState: 'NE',
    plateCountry: 'United States',
    vehicleId: 411,
    axleCount: 3,
    accountNumber: 797768,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1300,
    tollingPointName: 'Anclote (Southern)',
    laneType: 'MB',
    laneNumber: '06',
    laneName: '06B',
    expectedAmount: 0.03,
    collectedAmount: null
  },
  {
    id: 194,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T10:49:44Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 279724,
    plateState: 'MO',
    plateCountry: 'United States',
    vehicleId: 408,
    axleCount: 2,
    accountNumber: 524761,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1641,
    tollingPointName: 'SR570 Pace Road EB ON MP23',
    laneType: 'ORT',
    laneNumber: '50',
    laneName: '50B',
    expectedAmount: 0.1,
    collectedAmount: null
  },
  {
    id: 195,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T17:16:03Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 929823,
    plateState: 'AZ',
    plateCountry: 'United States',
    vehicleId: 337,
    axleCount: 4,
    accountNumber: 870226,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '07',
    laneName: '07Z',
    expectedAmount: 0.12,
    collectedAmount: null
  },
  {
    id: 196,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T11:22:09Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 48635,
    plateState: 'CA',
    plateCountry: 'United States',
    vehicleId: 691,
    axleCount: 4,
    accountNumber: 463413,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 2862,
    tollingPointName: 'SR23 Normandy Main SB MP42',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00B',
    expectedAmount: 0.7,
    collectedAmount: null
  },
  {
    id: 197,
    collectionStatus: 'Collected',
    transactionDateTime: '2020-04-01T13:56:20Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 407172,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 755,
    axleCount: 2,
    accountNumber: 221470,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1600,
    tollingPointName: 'Eastern Barrier',
    laneType: 'ADM',
    laneNumber: '00',
    laneName: '00Z',
    expectedAmount: 0.69,
    collectedAmount: 0.69
  },
  {
    id: 198,
    collectionStatus: 'New',
    transactionDateTime: '2020-04-01T23:36:24Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '',
    licensePlate: 740123,
    plateState: 'AB',
    plateCountry: 'Canada',
    vehicleId: 793,
    axleCount: 2,
    accountNumber: 921376,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3100,
    tollingPointName: 'Leesburg Mainline',
    laneType: 'DED',
    laneNumber: '10',
    laneName: '10Z',
    expectedAmount: 0.33,
    collectedAmount: null
  },
  {
    id: 199,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T06:23:14Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 323989,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 705,
    axleCount: 4,
    accountNumber: 257227,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 1200,
    tollingPointName: 'Spring Hill (Central)',
    laneType: 'MB',
    laneNumber: '77',
    laneName: '77B',
    expectedAmount: 0.96,
    collectedAmount: null
  },
  {
    id: 200,
    collectionStatus: 'Uncollectable',
    transactionDateTime: '2020-04-01T04:12:58Z',
    receivedDateTime: '2020-04-28 17:27:38 -0400',
    resolutionDateTime: '2020-04-28 17:27:38 -0400',
    licensePlate: 608213,
    plateState: 'QC',
    plateCountry: 'Canada',
    vehicleId: 915,
    axleCount: 2,
    accountNumber: 128525,
    operatorId: 1000,
    operatorName: 'Floridas Turnpike',
    operatorAbbrev: 'FDOT',
    tollingPointId: 3140,
    tollingPointName: 'Interstate-4',
    laneType: 'MB',
    laneNumber: '04',
    laneName: '04B',
    expectedAmount: 0.49,
    collectedAmount: null
  }
];
