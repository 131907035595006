import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../core/notifications/notification.service';
import { STYLE_CONTAINERS } from '../../../../shared/enums/style-containers.enum';
import { ContactModalComponent } from '../component/contact-modal.component';
import { ContactModalService } from '../service/contact-modal.service';
import * as ContactModalActions from './contact-modal.actions';

const CONTACT_DIALOG_ID = 'contact-modal-dialog';

@Injectable()
export class ContactModalEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private contactModalService: ContactModalService,
    private notificationService: NotificationService
  ) {}

  openContactModalEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactModalActions.openContactModal),
        tap(() => this.openContactModalDialog())
      ),
    { dispatch: false }
  );

  closeContactDialogEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactModalActions.closeContactModal),
        tap(() => {
          this.dialog.getDialogById(CONTACT_DIALOG_ID).close();
        })
      ),
    { dispatch: false }
  );

  sendContactMessageEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactModalActions.sendContactInformation),
      switchMap(action =>
        this.contactModalService.sendMessage(action.contactMessage).pipe(
          map(() => ContactModalActions.sendContactInformationSuccess()),
          catchError(() =>
            of(ContactModalActions.sendContactInformationFailure())
          )
        )
      )
    )
  );

  sendContactMessageSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ContactModalActions.sendContactInformationSuccess),
        tap(() =>
          this.notificationService.success(
            'Your message was successfully sent.'
          )
        )
      ),
    { dispatch: false }
  );

  openContactModalDialog() {
    this.dialog.open(ContactModalComponent, {
      panelClass: STYLE_CONTAINERS.CUSTOM_DIALOG_CONTAINER,
      id: CONTACT_DIALOG_ID
    });
  }
}
