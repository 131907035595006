import { Injectable } from '@angular/core';
import { USER_GROUPS } from '../model/user-groups.enum';
import { UrlTree, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class GroupMediatorService {
  constructor(private router: Router, private store: Store) {}

  canGroupActivateModule(
    isAuthenticated: boolean,
    userGroups: string[],
    module: USER_GROUPS,
    stateUrl: string
  ): boolean | UrlTree {
    if (!isAuthenticated) {
      return this.router.createUrlTree(['/login'], {
        queryParams: { state: stateUrl }
      });
    }
    if (this.canGroupLoadModule(userGroups, module)) {
      return true;
    }
    return this.router.createUrlTree([`/${userGroups[0]}`]);
  }

  private canGroupLoadModule(
    userGroups: string[],
    module: USER_GROUPS
  ): boolean {
    if ((!userGroups || userGroups.length < 1) && !module) {
      return true;
    }
    if (userGroups?.some(ug => ug === module)) {
      return true;
    }
    return false;
  }

  canLoadModule(
    userGroups: string[],
    module: USER_GROUPS,
    isAuthenticated: boolean
  ) {
    if (!isAuthenticated) {
      this.router.navigateByUrl('login');
      return false;
    }
    if ((!userGroups || userGroups.length === 0) && !module) {
      return true;
    }
    const canLoadModule = this.canGroupLoadModule(userGroups, module);
    if (!canLoadModule) {
      this.router.navigateByUrl(`/${userGroups[0]}`);
      return false;
    }
    return true;
  }
}
