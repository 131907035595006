import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../../core/core.state';
import * as ContactModalActions from './contact-modal.actions';

export const contactModalFeatureKey = 'contactModal';

export interface ContactModalState {
  isModalOpen: boolean;
  isSendingMessage: boolean;
  isSendMessageFailed: boolean;
  isSendMessageSucceeded: boolean;
}

export const initialState: ContactModalState = {
  isModalOpen: false,
  isSendMessageFailed: false,
  isSendingMessage: false,
  isSendMessageSucceeded: false
};

export interface State {
  [contactModalFeatureKey]: ContactModalState;
}

export const selectContactModalState = createFeatureSelector<
  AppState,
  ContactModalState
>(contactModalFeatureKey);

export const contactModalReducer = createReducer(
  initialState,
  on(ContactModalActions.openContactModal, (state, action) => ({
    ...state,
    isModalOpen: true,
    isSendMessageFailed: false,
    isSendMessageSucceeded: false
  })),
  on(ContactModalActions.sendContactInformation, (state, action) => ({
    ...state,
    isSendingMessage: true,
    isSendMessageFailed: false
  })),
  on(ContactModalActions.sendContactInformationFailure, (state, action) => ({
    ...state,
    isSendingMessage: false,
    isSendMessageFailed: true
  })),
  on(ContactModalActions.sendContactInformationSuccess, (state, action) => ({
    ...state,
    isSendingMessage: false,
    isSendMessageFailed: false,
    isSendMessageSucceeded: true
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return contactModalReducer;
}
