import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import {
  DefaultDataServiceConfig,
  EntityDataModule,
  PersistenceResultHandler,
  EntityCollectionReducerMethodsFactory
} from '@ngrx/data';
import { InMemoryDataService } from './in-memory-api/in-memory-data.service';
import { AdditionalPersistenceResultHandler } from './ngrx-data-handlers/additional-persistence-result-handler';
import { AdditionalEntityCollectionReducerMethodsFactory } from './ngrx-data-handlers/additional-entity-collection-reducer-methods-factory.service';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.awsServerUrl,
  timeout: 3000
};

const pluralNames = {
  Transactions: 'Transactions',
  CustomerLedger: 'CustomerLedger'
};

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // core
    CoreModule,

    // app
    AppRoutingModule,
    HttpClientModule,
    environment.production
      ? []
      : HttpClientInMemoryWebApiModule.forFeature(InMemoryDataService, {
          delay: 300,
          passThruUnknownUrl: true
        }),
    EntityDataModule.forRoot({ pluralNames })
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    {
      provide: PersistenceResultHandler,
      useClass: AdditionalPersistenceResultHandler
    },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory
    }
  ]
})
export class AppModule {}
