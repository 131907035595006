<mat-dialog-actions align="end">
  <button mat-icon-button mat-dialog-close>
    <fa-icon icon="times" [matTooltip]="'Close'" matTooltipPosition="above"></fa-icon>
  </button>
</mat-dialog-actions>
<h3 mat-dialog-title>Contact Us</h3>

<mat-dialog-content class="mat-typography table-container">

  <p>To contact us please fill the form below, we will get back to you in 48 hours or less.</p>


  <form [formGroup]="contactForm" class="contact-form">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" [errorStateMatcher]="matcher" placeholder="" required>
      <mat-hint>Your name and surname.</mat-hint>
      <mat-error *ngIf="contactForm.controls.name.hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"
        placeholder="Ex. pat@example.com" required>
      <mat-hint>An email where we can contact you.</mat-hint>
      <mat-error
        *ngIf="contactForm.controls.email.hasError('email') && !contactForm.controls.email.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="contactForm.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Subject</mat-label>
      <input matInput formControlName="subject" [errorStateMatcher]="matcher" placeholder="My Account." required>
      <mat-hint>Why you are contacting us.</mat-hint>
      <mat-error *ngIf="contactForm.controls.subject.hasError('required')">
        A subject is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput required formControlName="message"></textarea>
      <mat-error *ngIf="contactForm.controls.subject.hasError('required')">
        A message is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </form>


  <div class="error" *ngIf="isSendFailed$ | async">
    <mat-icon>error</mat-icon>
    <span > There was a problem sending your message. <br /> Please try again in a
      moment or contact support if the issue persists. </span>
  </div>

  <div class="success" *ngIf="isSendSucceeded$ | async">
    <mat-icon>check_circle</mat-icon>
    <span > Your message was sent. <br /> We will be responding at the email provided. </span>
  </div>

</mat-dialog-content>

<div mat-dialog-actions>

  <button mat-mini-fab color="accent" aria-label="Send" matTooltip="Send" class="send-button"
    [disabled]="(!contactForm.valid) || (isSending$ | async) || (isSendSucceeded$ | async)">
    <mat-spinner *ngIf="isSending$ | async" mode="indeterminate" diameter="20"></mat-spinner>
    <mat-icon *ngIf="!(isSending$ | async)" [inline]="true" class="send-icon" (click)="sendMessage()">send</mat-icon>
  </button>
</div>
