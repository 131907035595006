import { createReducer, on } from '@ngrx/store';
import { Transaction } from '../../../../../shared/models/transaction.model';
import { Activity } from '../../activities/model/activity.model';
import * as DashboardActions from './user-dashboard.actions';
import * as MakeAPaymentActions from '../../../make-a-payment/store/make-a-payment.actions';

export const dashboardFeatureKey = 'dashboard';

export interface UserDashboardState {
  accountNumber: string;
  lastTrips: Transaction[];
  lastActivities: Activity[];
  balance: number;
  isDashboardLoaded: boolean;
  isDashboardLoading: boolean;
  isDashboardActivitiesLoading: boolean;
  isCreatingPayment: boolean;
  isPaymentCreationFailed: boolean;
}

export const initialState: UserDashboardState = {
  accountNumber: null,
  lastTrips: [],
  lastActivities: [],
  balance: 0,
  isDashboardLoaded: false,
  isDashboardLoading: false,
  isCreatingPayment: false,
  isPaymentCreationFailed: false,
  isDashboardActivitiesLoading: false
};

export const userDashboardReducer = createReducer(
  initialState,
  on(DashboardActions.loadUserDashboard, state => ({
    ...state,
    isDashboardLoading: state.isDashboardActivitiesLoading ? false : true,
    isDashboardLoaded: false
  })),
  on(DashboardActions.newActivityInformationReceived, (state, action) => ({
    ...state,
    isDashboardActivitiesLoading: true
  })),
  on(DashboardActions.loadUserDashboardSuccess, (state, action) => ({
    ...state,
    accountNumber: action.data.customer.accountNumber,
    isDashboardLoading: false,
    isDashboardLoaded: true,
    isDashboardActivitiesLoading: false,
    balance: action.data.balance,
    lastTrips: action.data.lastTrips,
    lastActivities: action.data.activities
  })),
  on(DashboardActions.loadUserDashboardFailure, state => ({
    ...state,
    isDashboardLoaded: false,
    isDashboardLoading: false
  })),
  on(MakeAPaymentActions.actionCreatePayment, (state, action) => ({
    ...state,
    isCreatingPayment: true,
    isPaymentCreationFailed: false
  })),
  on(MakeAPaymentActions.actionOpenMakeAPaymentModal, state => ({
    ...state,
    isPaymentCreationFailed: false
  })),
  on(MakeAPaymentActions.actionCreatePaymentSuccess, (state, action) => ({
    ...state,
    isCreatingPayment: false,
    isPaymentCreationFailed: false
  })),
  on(MakeAPaymentActions.actionCreatePaymentFailure, (state, action) => ({
    ...state,
    isCreatingPayment: false,
    isPaymentCreationFailed: true
  }))
);
