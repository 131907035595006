import { createAction, props } from '@ngrx/store';
import { Customer } from '../../../../../shared/models/customer/customer.model';

export const actionInitializeWizard = createAction(
  '[New Customer Wizard] Initialize',
  props<{ email: string }>()
);

export const actionSaveWizard = createAction(
  '[New Customer Wizard] Save Wizard',
  props<{ customer: Customer }>()
);

export const actionSaveWizardRequestSuccess = createAction(
  '[New Customer Wizard] Save Wizard Request Success'
);

export const actionSaveWizardRequestFailure = createAction(
  '[New Customer Wizard] Save Wizard Request Failure'
);

export const actionSaveWizardSuccess = createAction(
  '[New Customer Wizard] Save Wizard Success'
);

export const actionSaveWizardFailure = createAction(
  '[New Customer Wizard] Save Wizard Failure'
);
