import { Injectable, Injector } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { GroupMediatorService } from '../services/group-mediator.service';
import {
  selectIsAuthenticated,
  selectUserAssignedGroups
} from '../store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class SigninWizardGuard implements CanActivate, CanLoad {
  constructor(
    private store: Store,
    private groupMediatorService: GroupMediatorService,
    private injector: Injector
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.store.select(selectUserAssignedGroups).pipe(
      withLatestFrom(this.store.select(selectIsAuthenticated)),
      first(),
      map(([userGroups, isAuthenticated]) => {
        return this.groupMediatorService.canLoadModule(
          userGroups,
          null,
          isAuthenticated
        );
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectUserAssignedGroups).pipe(
      withLatestFrom(this.store.select(selectIsAuthenticated)),
      map(([userGroups, isAuthenticated]) => {
        return this.groupMediatorService.canGroupActivateModule(
          isAuthenticated,
          userGroups,
          null,
          state.url
        );
      })
    );
  }
}
