import { createAction, props } from '@ngrx/store';
import { FilterFieldValue } from '../../../../../shared/models/entities-tables/filter-field-value';
import { SearchEntitiesResponse } from '../../../../../shared/models/entities-tables/search-entities-response';
import { SortFieldDirection } from '../../../../../shared/models/entities-tables/sort-field-direction';
import { Transaction } from '../../../../../shared/models/transaction.model';

const sectionName = '[Trips] ';

export const initialLoadTrips = createAction(
  sectionName + 'Load if not already loaded'
);

export const loadTrips = createAction(sectionName + 'Load Trips');

export const loadTripsFirstPage = createAction(
  sectionName + 'Load Trips First Page'
);

export const loadTripsSuccess = createAction(
  sectionName + 'Load Trips Success',
  props<{ data: SearchEntitiesResponse<Transaction> }>()
);

export const loadTripsError = createAction(
  sectionName + 'Load Trips Error',
  props<{ error: string }>()
);

export const changeTripsPage = createAction(
  sectionName + 'Change Page',
  props<{ pageIndex: number; pageSize: number }>()
);

export const searchTrips = createAction(
  sectionName + 'Search',
  props<{ searchTerm: string | string[] }>()
);

export const sortTrips = createAction(
  sectionName + 'Sort',
  props<{ sortField: SortFieldDirection }>()
);

export const filterTrips = createAction(
  sectionName + 'Filter',
  props<{ filter: FilterFieldValue }>()
);

export const changeTripsDateRange = createAction(
  sectionName + 'Filter Date Range Changed',
  props<{ range: FilterFieldValue }>()
);
