import { createAction, props } from '@ngrx/store';
import { Customer } from '../../../../../shared/models/customer/customer.model';

export const actionLoadCustomer = createAction('[Customer] Load Customer');

export const actionEditCustomer = createAction(
  '[Profile] Edited customer profile',
  props<{ data: any }>()
);

export const actionSaveCustomer = createAction(
  '[Customer Profile] Save Customer',
  props<{ customer: Customer }>()
);

export const actionSaveCustomerRequestSuccess = createAction(
  '[Customer Profile] Save Customer Request Success',
  props<{ customer: Customer }>()
);

export const actionSaveCustomerSuccess = createAction(
  '[Customer Profile] Save Customer Success'
);

export const actionSaveCustomerFailure = createAction(
  '[Customer Profile] Save Customer Failuer',
  props<{ err: string }>()
);
