import { createSelector } from '@ngrx/store';
import { selectSignupWizardState } from '../../../signin-wizard.state';

export const selectWizardState = createSelector(
  selectSignupWizardState,
  state => state.wizard
);

export const selectWizardCustomer = createSelector(
  selectWizardState,
  state => state.customer
);

export const selectWizardIsSaving = createSelector(
  selectWizardState,
  state => state.isSaving
);
