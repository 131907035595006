import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DATE_RANGES } from '../../../../../shared/components/date-selector/model/date-ranges.enum';
import { FilterFieldValue } from '../../../../../shared/models/entities-tables/filter-field-value';
import * as SortFilterReducer from '../../../../../shared/sort-filter-page-entities-store/sort-filter-page-entities.reducer';
import { Invoice } from '../../../../operator/accounting/invoices/model/invoice.model';
import { newActivityInformationReceived } from '../../user-dashboard/store/user-dashboard.actions';
import { Activity } from '../model/activity.model';
import { Payment } from '../model/payment.model';
import * as ActivitiesActions from './activities.actions';

export const activitiesFeatureKey = 'activities';

export interface ActivitiesState
  extends EntityState<Activity>,
    SortFilterReducer.SortFilterPageState {
  loadingInvoices: string[];
  invoices: Invoice[];
  loadingPayments: string[];
  payments: Payment[];
  downloadingInvoices: string[];
  selectedDateRange: FilterFieldValue;
}

export const activitiesAdapter: EntityAdapter<Activity> = createEntityAdapter<
  Activity
>({ selectId: (activity: Activity) => activity.refId });
const { selectAll } = activitiesAdapter.getSelectors();
export const selectActivities = selectAll;

export const initialState: ActivitiesState = activitiesAdapter.getInitialState({
  ...SortFilterReducer.sortFilterInitialState,
  loadingInvoices: [],
  invoices: [],
  loadingPayments: [],
  payments: [],
  activeSortField: { field: 'time', direction: 'desc' },
  downloadingInvoices: [],
  selectedDateRange: {
    filterName: 'time',
    filterValue: [DATE_RANGES.LAST_WEEK]
  }
});

export const activitiesReducer = createReducer(
  initialState,
  on(ActivitiesActions.loadActivitiesFirstPage, (state, action) =>
    SortFilterReducer.getLoadFirstPageNewState(state, action, activitiesAdapter)
  ),
  on(ActivitiesActions.loadActivities, (state, action) =>
    SortFilterReducer.getLoadEntitiesNewState(state, action, activitiesAdapter)
  ),
  on(ActivitiesActions.changeActivitiesPage, (state, action) =>
    SortFilterReducer.getChangePageNewState(state, action, activitiesAdapter)
  ),
  on(ActivitiesActions.searchActivities, (state, action) =>
    SortFilterReducer.getSearchNewState(state, action)
  ),
  on(ActivitiesActions.filterActivities, (state, action) =>
    SortFilterReducer.getFilterNewState(state, action)
  ),
  on(ActivitiesActions.sortActivities, (state, action) =>
    SortFilterReducer.getSortNewState(state, action)
  ),
  on(ActivitiesActions.loadActivitiesSuccess, (state, action) =>
    SortFilterReducer.getEntitiesLoadedNewState(
      state,
      action,
      activitiesAdapter
    )
  ),
  on(ActivitiesActions.loadActivitiesError, (state, action) =>
    SortFilterReducer.getEntitiesLoadFailureNewState(state, activitiesAdapter)
  ),
  on(ActivitiesActions.getInvoice, (state, action) => ({
    ...state,
    loadingInvoices: state.loadingInvoices.some(i => i === action.refId)
      ? state.loadingInvoices
      : [...state.loadingInvoices, action.refId]
  })),
  on(ActivitiesActions.getInvoiceSuccess, (state, action) => ({
    ...state,
    loadingInvoices: state.loadingInvoices.filter(i => i !== action.refId),
    invoices: [
      ...state.invoices.filter(i => i.id !== action.refId),
      action.invoice
    ]
  })),
  on(ActivitiesActions.getInvoiceFailure, (state, action) => ({
    ...state,
    loadingInvoices: state.loadingInvoices.filter(i => i !== action.refId)
  })),
  on(ActivitiesActions.getPayment, (state, action) => ({
    ...state,
    loadingPayments: state.loadingPayments.some(p => p === action.refId)
      ? state.loadingPayments
      : [...state.loadingPayments, action.refId]
  })),
  on(ActivitiesActions.getPaymentFailure, (state, action) => ({
    ...state,
    loadingPayments: state.loadingPayments.filter(i => i !== action.refId)
  })),
  on(ActivitiesActions.getPaymentSuccess, (state, action) => ({
    ...state,
    loadingPayments: state.loadingPayments.filter(i => i !== action.refId),
    payments: [
      ...state.payments.filter(p => p.id !== action.refId),
      action.payment
    ]
  })),
  on(newActivityInformationReceived, (state, action) => ({
    ...state,
    isLoaded: false
  })),
  on(ActivitiesActions.downloadInvoicePdf, (state, action) => ({
    ...state,
    downloadingInvoices: [...state.downloadingInvoices, action.id]
  })),
  on(ActivitiesActions.downloadInvoicePdfSuccess, (state, action) => ({
    ...state,
    downloadingInvoices: state.downloadingInvoices.filter(i => i !== action.id)
  })),
  on(ActivitiesActions.downloadInvoicePdfFailure, (state, action) => ({
    ...state,
    downloadingInvoices: state.downloadingInvoices.filter(i => i !== action.id)
  })),
  on(ActivitiesActions.changeDateRange, (state, action) => ({
    ...state,
    selectedDateRange: action.dateRange
  }))
);
