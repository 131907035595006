import { TollingPointCollection } from '../../features/admin/home/services/tolling-point-collection';

export const RATES: TollingPointCollection = {
  id: 1,
  operatorId: '1x',
  operatorName: 'MDX',
  tollingPoints: [
    {
      tollingPointId: '1',
      stateAbbrev: 'FL',
      roadName: 'Gratigny',
      roadNumber: 'SR924',
      trafficDirection: 'East',
      intersection: '57th Ave',
      sequenceNumber: 1,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.94
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 1.88
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 2.82
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 3.76
        }
      ],
      additionalAxleRate: 0.94
    },
    {
      tollingPointId: '2',
      stateAbbrev: 'FL',
      roadName: 'Gratigny',
      roadNumber: 'SR924',
      trafficDirection: 'East',
      intersection: '42nd Ave',
      sequenceNumber: 2,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.94
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 1.88
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 2.82
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 3.76
        }
      ],
      additionalAxleRate: 0.94
    },
    // made up repeating last
    {
      tollingPointId: '3',
      stateAbbrev: 'FL',
      roadName: 'Airport Expressway',
      roadNumber: 'SR112',
      trafficDirection: 'East',
      intersection: '32nd Ave',
      sequenceNumber: 3,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.66
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 1.32
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 1.98
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 2.64
        }
      ],
      additionalAxleRate: 0.66
    },
    {
      tollingPointId: '4',
      stateAbbrev: 'FL',
      roadName: 'Airport Expressway',
      roadNumber: 'SR112',
      trafficDirection: 'East',
      intersection: '17th Ave',
      sequenceNumber: 4,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.66
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 1.32
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 1.98
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 2.64
        }
      ],
      additionalAxleRate: 0.66
    },
    {
      tollingPointId: '5',
      stateAbbrev: 'FL',
      roadName: 'Don Shula',
      roadNumber: 'SR874',
      trafficDirection: 'North',
      intersection: 'Turnpike',
      sequenceNumber: 5,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.46
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 0.92
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 1.38
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 1.84
        }
      ],
      additionalAxleRate: 0.46
    },
    {
      tollingPointId: '6',
      stateAbbrev: 'FL',
      roadName: 'Don Shula',
      roadNumber: 'SR874',
      trafficDirection: 'North',
      intersection: 'Killian',
      sequenceNumber: 6,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.46
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 0.92
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 1.38
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 1.84
        }
      ],
      additionalAxleRate: 0.46
    },
    {
      tollingPointId: '7',
      stateAbbrev: 'FL',
      roadName: 'Don Shula',
      roadNumber: 'SR874',
      trafficDirection: 'North',
      intersection: 'SR826',
      sequenceNumber: 7,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.94
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 1.88
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 2.82
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 3.76
        }
      ],
      additionalAxleRate: 0.94
    },
    {
      tollingPointId: '8',
      stateAbbrev: 'FL',
      roadName: 'Don Shula',
      roadNumber: 'SR874',
      trafficDirection: 'South',
      intersection: 'SR826',
      sequenceNumber: 8,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.94
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 1.88
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 2.82
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 3.76
        }
      ],
      additionalAxleRate: 0.94
    },
    {
      tollingPointId: '9',
      stateAbbrev: 'FL',
      roadName: 'Don Shula',
      roadNumber: 'SR874',
      trafficDirection: 'South',
      intersection: 'Killian',
      sequenceNumber: 9,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.46
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 0.92
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 1.38
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 1.84
        }
      ],
      additionalAxleRate: 0.46
    },
    {
      tollingPointId: '10',
      stateAbbrev: 'FL',
      roadName: 'Don Shula',
      roadNumber: 'SR874',
      trafficDirection: 'South',
      intersection: 'Turnpike',
      sequenceNumber: 10,
      axleRates: [
        {
          rateId: '1',
          vehicleClass: 'passenger',
          numberAxles: 2,
          rateDollars: 0.46
        },
        {
          rateId: '2',
          vehicleClass: 'bus/rv/trailer',
          numberAxles: 3,
          rateDollars: 0.92
        },
        {
          rateId: '3',
          vehicleClass: 'trailer',
          numberAxles: 4,
          rateDollars: 1.38
        },
        {
          rateId: '4',
          vehicleClass: 'tractor trailer',
          numberAxles: 5,
          rateDollars: 1.84
        }
      ],
      additionalAxleRate: 0.46
    }
  ]
};
