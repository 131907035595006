import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Vehicle } from '../../../../../shared/models/vehicle/vehicle.model';
import * as VehicleActions from '../../../../shared-features/vehicles/store/vehicles.actions';

export const vehiclesFeatureKey = 'vehicles';

export interface VehiclesState extends EntityState<Vehicle> {
  selectedVehicleId: string;
  isCreating: boolean;
  isEditing: boolean;
  isGeneratingTransactions: boolean;
  isSaving: boolean;
}

export const vehiclesAdapter: EntityAdapter<Vehicle> = createEntityAdapter<
  Vehicle
>();
export const { selectAll } = vehiclesAdapter.getSelectors();
export const selectVehicles = selectAll;

export const initialState: VehiclesState = vehiclesAdapter.getInitialState({
  selectedVehicleId: null,
  isCreating: false,
  isEditing: false,
  isGeneratingTransactions: false,
  isSaving: false
});

export const vehiclesReducer = createReducer(
  initialState,
  on(VehicleActions.actionEditVehicle, (state, action) => {
    return {
      ...state,
      selectedVehicleId: action.vehicleId,
      isEditing: true,
      isCreating: false
    };
  }),
  on(VehicleActions.actionCancelVehicleEdit, state => {
    return {
      ...state,
      isCreating: false,
      isEditing: false,
      selectedVehicleId: null
    };
  }),
  on(VehicleActions.actionStartCreatingVehicle, state => {
    return {
      ...state,
      selectedVehicleId: null,
      isCreating: true,
      isEditing: false
    };
  }),
  on(VehicleActions.actionSaveVehicle, (state, action) => {
    return {
      ...state,
      selectedVehicleId: action.vehicle.id,
      isEditing: false,
      isCreating: false,
      isSaving: true
    };
  }),
  on(VehicleActions.actionSaveVehicleSuccess, (state, action) =>
    vehiclesAdapter.upsertOne(action.vehicle, { ...state, isSaving: false })
  ),
  on(VehicleActions.actionSaveVehicleFailure, (state, action) => ({
    ...state,
    isSaving: false
  })),
  on(VehicleActions.actionSelectVehicle, (state, action) => {
    return {
      ...state,
      selectedVehicleId: action.vehicleId,
      isEditing: false,
      isCreating: false
    };
  }),
  on(VehicleActions.actionDeselectVehicle, state => {
    return {
      ...state,
      selectedVehicleId: null,
      isEditing: false,
      isCreating: false
    };
  }),
  on(VehicleActions.actionDeleteVehicle, state => {
    return {
      ...state,
      selectedVehicleId: null,
      isEditing: false,
      isCreating: false
    };
  }),
  on(VehicleActions.actionDeleteVehicleSuccess, (state, action) =>
    vehiclesAdapter.removeOne(action.vehicle.id, state)
  )
);
