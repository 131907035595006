import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { VehiclesState, vehicleReducer } from './home/vehicles/reducers';
import {
  UserDashboardState,
  userDashboardReducer
} from './home/user-dashboard/store/user-dashboard.reducer';
import {
  CustomerState,
  customerReducer
} from './home/customer/store/customer.reducer';
import { tripsReducer, TripsState } from './home/trips/store/trips.reducer';
import {
  activitiesReducer,
  ActivitiesState
} from './home/activities/store/activities.reducer';

export const FEATURE_NAME = 'user';

export const selectUserState = createFeatureSelector<State, UserState>(
  FEATURE_NAME
);

export interface UserState {
  vehicles: VehiclesState;
  activities: ActivitiesState;
  trips: TripsState;
  userDashboard: UserDashboardState;
  customer: CustomerState;
}

export interface State {
  user: UserState;
}

export const reducers: ActionReducerMap<UserState> = {
  vehicles: vehicleReducer,
  activities: activitiesReducer,
  trips: tripsReducer,
  userDashboard: userDashboardReducer,
  customer: customerReducer
};
