import * as AuthActions from './auth.actions';
import { createReducer, on } from '@ngrx/store';
import { JwtDecoderService } from '../services/jwt-decoder.service';

export interface AuthState {
  isAuthenticated: boolean;
  lastVisitedUrl: string;
  requestedUrl: string;

  verifier: string;
  email: any;
  profilePic: string;
  userGroups: string[];
  accessToken: string;
  idToken: string;
  refreshToken: string;
  tokenExpiration: string;
  isRefreshingCredentials: boolean;
  error: string;

  identityId: string;
  sessionToken: string;
  secretAccessKey: string;
  accessKeyId: string;
  accessKeyExpiration: number;
  accessKeyExpirationDate: string;
  isRefreshingAccessKey: boolean;

  isKeepingSocketAlive: boolean;
}

export const initialState: AuthState = {
  verifier: '',
  lastVisitedUrl: '',
  requestedUrl: '',
  isAuthenticated: undefined,
  email: '',
  profilePic: '',
  userGroups: [],
  accessToken: '',
  idToken: '',
  refreshToken: '',
  tokenExpiration: '',
  isRefreshingCredentials: false,
  error: '',

  identityId: '',
  sessionToken: '',
  secretAccessKey: '',
  accessKeyId: '',
  accessKeyExpiration: 0,
  accessKeyExpirationDate: '',
  isRefreshingAccessKey: false,

  isKeepingSocketAlive: false
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.authInitiateSilentLogin, (state, action) => ({
    ...state,
    requestedUrl: action.redirectUrl
  })),
  on(AuthActions.authGetTokens, (state, action) => ({
    ...state,
    requestedUrl: action.requestedUrl
  })),
  on(AuthActions.authGetTokensSuccess, (state, action) => {
    const profilePic = JwtDecoderService.decodeToken(action.tokens.id_token)
      ?.picture;
    return {
      ...state,
      accessToken: action.tokens.access_token,
      idToken: action.tokens.id_token,
      refreshToken: action.tokens.refresh_token,
      tokenExpiration: AuthReducerUtil.getExpirationDate(
        action.tokens.expires_in
      ),
      userGroups: action.userGroups,
      profilePic: profilePic || '',
      email: action.email
    };
  }),
  on(AuthActions.authGetTokensFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    error: 'Auth Get Tokens Error: ' + action.error.error.error
  })),
  on(AuthActions.authRefreshTokens, state => ({
    ...state,
    accessToken: '',
    idToken: '',
    sessionToken: '',
    isRefreshingCredentials: true
  })),
  on(AuthActions.authRefreshTokensSuccess, (state, action) => {
    const profilePic = JwtDecoderService.decodeToken(action.tokens.id_token)
      ?.picture;
    return {
      ...state,
      accessToken: action.tokens.access_token,
      idToken: action.tokens.id_token,
      tokenExpiration: AuthReducerUtil.getExpirationDate(
        action.tokens.expires_in
      ),
      email: action.email,
      profilePic: profilePic || '',
      userGroups: action.userGroups
    };
  }),
  on(AuthActions.authRefreshTokensFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    error: 'Auth Refresh Tokens Error: ' + action.error.error.error,
    isRefreshingCredentials: false
  })),
  on(AuthActions.authLogout, () => initialState),
  on(AuthActions.getIdentitySuccess, (state, action) => ({
    ...state,
    identityId: action.identity.IdentityId
  })),
  on(AuthActions.getIdentityFalure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    isRefreshingCredentials: false,
    error: 'Auth Get Identity Failure Error: ' + action.error.error.error
  })),
  on(AuthActions.getCredentialsForIdentitySuccess, (state, action) => ({
    ...state,
    accessKeyId: action.credentials.Credentials.AccessKeyId,
    accessKeyExpiration: action.credentials.Credentials.Expiration,
    secretAccessKey: action.credentials.Credentials.SecretKey,
    sessionToken: action.credentials.Credentials.SessionToken,
    accessKeyExpirationDate: new Date(
      action.credentials.Credentials.Expiration * 1000
    ).toISOString()
  })),
  on(AuthActions.getCredentialsForIdentityFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    isRefreshingCredentials: false,
    error:
      'Auth Get Credentials For Identity Error: ' + action.error.error.error
  })),
  on(AuthActions.authFinalizeLoginSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    isRefreshingCredentials: false,
    isKeepingSocketAlive: true
  })),
  on(AuthActions.authFinalizeRefreshCredentialsSuccess, (state, action) => ({
    ...state,
    isRefreshingAccessKey: false,
    isRefreshingCredentials: false
  })),
  on(AuthActions.fatalAuthError, (state, action) => ({
    ...state,
    isAuthenticated: false,
    isKeepingSocketAlive: false
  }))
);

class AuthReducerUtil {
  static getExpirationDate(secondsUntilExpiration: number): string {
    let expirationDate = new Date();
    expirationDate.setSeconds(
      expirationDate.getSeconds() + secondsUntilExpiration
    );
    return expirationDate.toISOString();
  }
}
